<template>
  <van-button :disabled="disabled" @click="handleClick">
    <div class="chat_lottie_icon" ref="chatLottieAnRef"></div>
    <span>{{ title }}</span>
  </van-button>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import lottie, { AnimationItem } from 'lottie-web';
import animationData from "@/assets/image/chatbtnlottie.json";

export default defineComponent({
  props: {
    autoPlay: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  setup(props, { emit }) {
    const chatLottieAnRef = ref();
    let chatLottieAn: AnimationItem | null;

    const handleClick = (event: MouseEvent) => {
      emit('click', event);
    };

    const playOrPause = (play: boolean) => {
      if (play) {
        chatLottieAn?.play();
      } else {
        chatLottieAn?.stop();
      }
    };

    onMounted(() => {
      const options = {
        animationData: animationData,
        loop: true,
        autoplay: props.autoPlay
      };

      chatLottieAn = lottie.loadAnimation({
        container: chatLottieAnRef.value,
        renderer: 'svg',
        ...options
      });
    });

    onBeforeUnmount(() => {
      if (chatLottieAn) {
        chatLottieAn.stop();
        chatLottieAn.destroy();
        chatLottieAn = null;
      }
    });

    return {
      handleClick,
      playOrPause,
      chatLottieAnRef
    };
  }
});
</script>

<style scoped lang="less">
.van-button {
  :deep(.van-button__text) {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }

  .chat_lottie_icon {
    width: 14px;
    height: 14px;
    margin-right: 4px;

    :deep(svg) {
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -webkit-touch-callout: none; // 禁用系统默认菜单、img元素比如保存图像等等
      pointer-events: none; // 阻止点击、状态变化和鼠标指针变化：
    }
  }
}
</style>