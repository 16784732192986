<template>
  <van-loading type="spinner" />
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { setTheme } from "../theme/theme";


export default defineComponent({

  setup(props, { emit }) {
   

    onMounted(() => {
      setTheme("dark");
    });

    return {
    }
  }
});
</script>

<style scoped lang="less">
.van-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background: rgba(1, 1, 1, 0.5);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
}
</style>