import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ecf1ef34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "trigger_view_content" }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: [
      _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
      _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeVideoButtonClick && _ctx.closeVideoButtonClick(...args)))
    ],
    class: "trigger_view_bg"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("video", {
        class: "video-js play_view_content",
        id: _ctx.playerId,
        "webkit-playsinline": "true",
        playsinline: "true",
        "x5-video-player-type": "h5",
        "x5-video-player-fullscreen": "true",
        "x5-video-orientation": "portraint"
      }, null, 8, _hoisted_2)
    ])
  ]))
}