<template>
  <div class="card-container" :style="{ backgroundImage: `url(${backgroundImg})` }">
    <div v-if="isMySelf" class="card-slider" @click="toMyFavorites">
      <van-image :src="require('../assets/image/icon-card.png')"></van-image>
      <span>我的卡包</span>
    </div>
    <div v-else class="card-slider" @click="toMyLinkard">
      <van-image :src="require('../assets/image/icon-card.png')"></van-image>
      <span>我的联卡</span>
    </div>
    <div class="card-body">
      <div class="linkard-box">
        <span class="text">LINKARD</span>
        <span class="line"></span>
      </div>
      <div class="empty-card">
        <van-image :src="require('../assets/image/icon-empty-card.svg')"></van-image>
        <div v-if="isMySelf" class="empty-card-text">
          <div>您还未创建联卡</div>
          <div>请使用电脑前往以下链接开始创建</div>
          <a :href="pcJumpUrl">{{ pcJumpUrl }}</a>
        </div>
        <div v-else class="empty-card-text">
          <div>该联卡不存在</div>
        </div>
      </div>
    </div>
    <div class="bottom_white"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';


export default defineComponent({
  props: {
    isMySelf: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {

    onMounted(() => {
    });

    const toMyFavorites = () => {
      emit('toMyFavorites');
    };
    const toMyLinkard = () => {
      emit('toMyLinkard');
    }

    return {
      backgroundImg: require('../assets/image/default-body-bg.svg'),
      pcJumpUrl: process.env.VUE_APP_ADMIN_URL,
      toMyFavorites,
      toMyLinkard
    }
  }
});
</script>

<style lang="less" scoped>
.card-container {
  height: 100%;
  background: #DEDEDE;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;

  .linkard-box {
    display: flex;
    font-size: 12px;
    color: #F3EC3F;
    align-items: center;
    padding-top: 24px;
    padding-left: 20px;

    .line {
      height: 1px;
      background: #F3EC3F;
      width: 72px;
      margin-left: 25px;
    }
  }

  .card-slider {
    display: flex;
    width: 74px;
    font-size: 12px;
    position: absolute;
    top: 66px;
    align-items: center;
    color: #f0f0f0;
    background: #242424;
    padding: 16px 18px 16px 10px;
    border: 1px solid #f0f0f0;
    border-left: none;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;

    .van-image {
      width: 10px;
      margin-right: 10px;
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    background: #404040;
    border-radius: 28px;
    margin: 16px 10px;
    flex-grow: 1;

    .empty-card {
      width: 100%;
      font-size: 16px;
      color: #f0f0f0;
      margin: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .van-image {
        width: 100px;
      }

      .empty-card-text {
        text-align: center;
        line-height: 24px;
        width: 100%;
        font-weight: 600;
        margin-top: 12px;
      }

      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  .bottom_white {
    height: 248px;
    width: 100%;
    background-color: rgba(240, 240, 240, 0.92);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }
}
</style>