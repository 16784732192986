import { linkardNet } from "@/net/linkardNet";
import { initConfig, setShareInfo } from "./useWxSDK";

export function useWxShare(shareConfig: {
  title: string;
  imgUrl: string;
  link: string;
  desc: string;
}) {
  const signUrl = window.location.href.split("#")[0];
  linkardNet
    .getWxJsapiTicketSignature(
      encodeURIComponent(signUrl),
      process.env.VUE_APP_WX_APPNAME
    )
    .then(
      (response) => {
        initConfig(response).then(() => {
          // 注入wx.config成功后，设置微信分享相关
          setShareInfo({
            ...shareConfig,
          });
        });
      },
      (ex) => {}
    );
}
