export const themes = {
  default: {
    primaryColor: `${222},${222},${222}`,
    primaryTextColor: `${74}, ${144},${226}`,
    loadColor: `${36},${36},${36}`,
    loadTextColor: `${36},${36},${36}`,
    backArrowColor:`${36},${36},${36}`,
    dividerColor:`${0},${0},${0}`,
    // background: `url(${require('../assets/image/bg.svg')})`
  },
  dark: {
    primaryColor: `${0},${0},${0}`,
    primaryTextColor: `${0},${0},${0}`,
    loadColor: `${240},${240},${240}`,
    loadTextColor: `${240},${240},${240}`,
    backArrowColor:`${240},${240},${240}`,
    dividerColor:`${222},${222},${222}`,
  },
};
