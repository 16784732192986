<template>
  <div @click.stop class="trigger_view_bg" @click="closeVideoButtonClick">
    <div class="trigger_view_content">
      <video class="video-js play_view_content" :id="playerId" webkit-playsinline="true" playsinline="true"
        x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-orientation="portraint"></video>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import videojs from "video.js";

export default defineComponent({

  props: {
    showVideoUrl: {
      type: String,
      default: '',
    }
  },
  setup(props, { emit }) {

    let playerId = "playerIdlinkcard";
    let player: any = null;

    onMounted(() => {
      videojs.addLanguage("zh-CN", {
        "You aborted the media playback": "视频播放被终止",
        "A network error caused the media download to fail part-way.": "网络错误导致视频下载中途失败。",
        "The media could not be loaded, either because the server or network failed or because the format is not supported.": "视频因格式不支持或者服务器或网络的问题无法加载。",
        "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "由于视频文件损坏或是该视频使用了你的浏览器不支持的功能，播放终止。",
        "No compatible source was found for this media.": "无法找到此视频兼容的源。",
      });

      player = videojs(playerId, {
        bigPlayButton: true, // 是否显示播放按钮（这个播放按钮默认会再左上方，需用CSS设置居中）
        textTrackDisplay: true,
        posterImage: true,
        errorDisplay: true,
        controlBar: true,
        autoplay: true, // 这个说是自动播放，但是本人使用无效
        controls: true,
      }, function () {
        play();
      });

      if (props.showVideoUrl) {
        player.src(props.showVideoUrl);
        player.load();
      }

      player.on("ended", () => { // 播放完成
        const error = player!.error();
      });

      player.on("fullscreenchange", () => {

      });
    });

    const closeVideoButtonClick = () => {
      if (player != null) {
        player.pause();
        player.dispose();
        emit("dismissVideoTriggerPlay");
      }
    };

    const play = () => {
      if (player) {
        player.play();
      }
    }

    return {
      playerId,
      closeVideoButtonClick
    }
  }
});
</script>

<style lang="less" scoped>
.trigger_view_bg {
  background: #242424;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1002;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: auto;

  .trigger_view_content {
    width: 80%;
    height: 44.8vw;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    position: absolute;
    border-radius: 14px;
    border: 2px solid #f0f0f0;

    .play_view_content {
      width: 100%;
      height: 100%;
      border-radius: 14px;
      background-color: transparent !important;
      box-sizing: border-box;

      video {
        border-radius: 14px;
      }

      :deep(.video-js) {
        background-color: transparent !important;
      }

      :deep(.vjs-control-bar) {
        background-color: transparent !important;
        background: linear-gradient(180deg, hsla(0, 0%, 90%, 0), #242424);
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
      }
    }

    .close_button {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 60px;
      height: 36px;
      border-radius: 18px;
      border-width: 0;
      background: rgba(240, 240, 240, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      img {
        width: 12px;
        height: 12px;
      }
    }
  }
}
</style>