<template>
  <div class="chat_message_content">
    <div class="head_content">
      <span class="left_tips">LINKARD</span>
      <span>对话记录</span>
      <div class="right_line"></div>
    </div>
    <van-image
      class="back_action"
      :src="require('../assets/image/white_btn_icon.svg')"
      @click="close"
    ></van-image>
    <div class="chat-list-content">
      <van-list :finished="true">
        <van-cell v-for="(item, index) in list" :key="index">
          <ChatMessageListItem
            :isUmi="item.fromBot"
            :text="item.text"
            :sourceType="sourceType"
            :triggerType="item.triggerType"
            :fromAudio="item.fromAudio"
            :mediaUrl="item.mediaUrl"
            :url="item.url"
            :npcName="npcName"
            @dismissVideoTriggerPlayView="dismissVideoTriggerPlayView"
            @showVideoPaly="showVideoPaly"
          />
        </van-cell>
      </van-list>
    </div>
    <VideoTriggerPlayView
      v-if="showVideoPlayTriggerView"
      @dismissVideoTriggerPlay="dismissVideoTriggerPlayView"
      :showVideoUrl="showVideoUrl"
    >
    </VideoTriggerPlayView>
  </div>
</template>

<script lang="ts">
import ChatMessageListItem from "../components/chatMessageListItem.vue";
import { Ref, defineComponent, onMounted, ref } from "vue";
import VideoTriggerPlayView from "./videoTriggerPlayView.vue";

export default defineComponent({
  props: {
    linkardId: {
      type: String,
      default: "",
    },
    npcName: {
      type: String,
      default: "",
    },
    sourceType: {
      type: Number,
      default: -1,
    },
  },
  components: {
    VideoTriggerPlayView,
    ChatMessageListItem,
  },
  setup(props, { emit }) {
    const list: Ref<any[]> = ref([]);
    let showVideoPlayTriggerView = ref(false);
    let showVideoUrl = ref("");

    const close = () => {
      emit("closeChatMessageListView");
    };

    const loadLocalMessage = () => {
      const keys = Object.keys(localStorage);
      const filteredArray = keys.filter((element) =>
        element.includes(`${props.linkardId}_chatMessage`)
      );

      if (filteredArray.length > 0) {
        var localMessage = localStorage.getItem(filteredArray[0]);
        if (localMessage) {
          list.value = JSON.parse(localMessage);
          localMessage = null;
        } else {
        }
      } else {
      }
    };

    const dismissVideoTriggerPlayView = () => {
      showVideoPlayTriggerView.value = false;
    };

    const showVideoPaly = (url: string) => {
      showVideoUrl.value = url;
      showVideoPlayTriggerView.value = true;
    };

    onMounted(() => {
      loadLocalMessage();
    });

    return {
      list,
      showVideoPlayTriggerView,
      showVideoUrl,
      close,
      dismissVideoTriggerPlayView,
      showVideoPaly,
    };
  },
});
</script>

<style lang="less" scoped>
.chat_message_content {
  justify-content: center;
  display: flex;
  flex-direction: column;

  border-radius: 28px;
  background-color: rgb(240, 240, 240);
  width: calc(100% - 24px);
  height: calc(100% - 44px);
  border: 2px solid #242424;
  z-index: 2001;
  left: 10px;
  top: 16px;
  position: absolute;
  overflow-x: hidden;

  .head_content {
    font-size: 12px;
    color: #242424;
    display: flex;
    font-weight: 700;
    align-items: center;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

    .left_tips {
      position: absolute;
      left: 20px;
    }

    .right_line {
      position: absolute;
      right: 20px;
      height: 1px;
      width: 40px;
      background-color: #242424;
    }
  }

  .back_action {
    width: 48px;
    height: 48px;
    position: absolute;
    left: 10px;
    top: 50px;
  }

  .chat-list-content {
    padding: 0 10px;
    margin-top: 76px;
    width: 100%;
    height: calc(100% - 86px);
    overflow-y: scroll;

    :deep(.van-cell) {
      background-color: transparent;
      padding: 0;
    }

    :deep(.van-cell:after) {
      border-bottom: 0;
    }
  }
}
</style>
