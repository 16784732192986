import {
  createRouter,
  createWebHashHistory,
  type RouteLocationNormalized,
} from "vue-router";
// import { useCachedViewStoreHook } from "@/stores/cachedView";
import routes from "./routes";

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export interface toRouteType extends RouteLocationNormalized {
  meta: {
    title?: string;
    noCache?: boolean;
  };
}

router.beforeEach((to: toRouteType, from, next) => {
  // 路由缓存
  // useCachedViewStoreHook().addCachedView(to);
  // next();

  const path = window.location.pathname;
  const search = window.location.search;
  if (path !== "/" || search) {
    window.location.replace(`/#${path}${search}`);
  } else {
    next();
  }
});

router.afterEach(() => {});

export default router;
