<template>
  <div class="evaluation_content">
    <div class="head_content">
      <span>我的评测报告</span>
    </div>
    <van-image class="back_action" :src="require('../assets/image/white_btn_icon.svg')" @click="close"></van-image>
    <div class="evaluation-list-bg">
      <div class="empty-card" v-if="list.length == 0 && !listLoading">
        <van-image :src="require('../assets/image/icon-empty-dark.png')"></van-image>
        <div class="empty-card-text">
          <div>暂无内容</div>
        </div>
      </div>
      <div class="evaluation-list" v-if="list.length != 0">
        <van-pull-refresh class="list-pull-refresh-view" v-model="lisrefreshing" @refresh="listOnRefresh">
          <van-list loading-text="加载中..." v-model:loading="listLoading" :finished="listFinished" @load="listOnLoad"
            finished-text="">
            <van-cell v-for="(item, index) in list" @click="evaluationItemClick(item)">
              <div class="evaluation_item">
                <van-image :src="require('../assets/image/evaluation_detail_icon.svg')"></van-image>
                <div class="evaluation_info">
                  <div class="title">{{ item.title }}</div>
                  <div class="space_line"></div>
                  <span class="time">{{ item.createDate }}</span>
                </div>
              </div>
            </van-cell>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <Loading v-show="listLoading" />
  </div>
</template>

<script lang="ts">
import { Ref, defineComponent, onMounted, ref } from 'vue';
import { linkardNet } from '@/net/linkardNet';
import { HttpResponse } from "@/net/http";
import { showToast } from "vant";
import { getSlashDate } from '@/utils/dateUtils';

export default defineComponent({

  props: {
    linkardId: {
      type: String,
      default: ''
    },
  },
  components: {
  },
  setup(props, { emit }) {

    const list: Ref<any[]> = ref([]);

    let pageNum = 1;
    let pageSize = 10;
    let listRefresh = ref(false);
    let lisrefreshing = ref(false);
    let listLoading = ref(false);
    let listFinished = ref(false);

    const close = () => {
      emit("closeMyEvaluationListView");
    };

    const listOnRefresh = () => {
      evaluationListRequest(true);
    };
    const listOnLoad = () => {
      evaluationListRequest(false);
    };

    const evaluationListRequest = async (pullDown: boolean) => {
      listLoading.value = true;
      if (pullDown) {
        pageNum = 1;
      }

      linkardNet.getScaleReportList(props.linkardId, pageNum, pageSize).then(
        (response) => {
          lisrefreshing.value = false;
          listLoading.value = false;

          response.list.forEach((item: any) => {
            item.createDate = getSlashDate(item.createTime);
          });

          if (pullDown) {
            list.value = response.list;
          } else {
            list.value = [...list.value, ...response.list];
          }
          if (response.list.length >= 10) {
            pageNum += 1;
            listFinished.value = false;
          } else {
            listFinished.value = true;
          }
        },
        (ex) => {
          listLoading.value = false;
          listFinished.value = true;
          lisrefreshing.value = false;
          const error = ex as HttpResponse<any>;
          showToast(error.message);
        },
      );
    };

    const evaluationItemClick = (item: any) => {
      emit('showEvaluationDetailView', item.id, 1);
    };

    onMounted(() => {
      evaluationListRequest(true);
    });

    return {
      list,
      pageNum,
      pageSize,
      listRefresh,
      lisrefreshing,
      listLoading,
      listFinished,
      close,
      listOnRefresh,
      listOnLoad,
      evaluationItemClick
    }
  }
});
</script>

<style lang="less" scoped>
.evaluation_content {
  justify-content: center;
  display: flex;
  flex-direction: column;

  border-radius: 28px;
  background-color: rgb(240, 240, 240);
  width: calc(100% - 24px);
  height: calc(100% - 44px);
  border: 2px solid #242424;
  z-index: 2001;
  left: 10px;
  top: 16px;
  position: absolute;
  overflow-x: hidden;

  .head_content {
    font-size: 14px;
    color: #242424;
    display: flex;
    font-weight: 600;
    align-items: center;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
  }

  .back_action {
    width: 48px;
    height: 48px;
    position: absolute;
    left: 10px;
    top: 20px;
  }

  .evaluation-list-bg {
    margin-top: 10px;
    width: 100%;
    height: calc(100% - 100px);
    overflow-y: hidden;

    .empty-card {
      width: 100%;
      font-size: 16px;
      color: #f0f0f0;
      margin: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-content: start;
      align-items: center;
      margin-top: calc(50% - 50px);

      .van-image {
        width: 100px;
      }

      .empty-card-text {
        margin-top: 12px;
        text-align: center;
        line-height: 24px;
        width: 100%;
        bottom: -20px;
        color: #242424;
        font-weight: 600;
      }
    }

    .evaluation-list {
      display: flex;
      margin: 20px 20px 0;
      padding: 0 10px;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 100%;
      overflow-y: scroll;
      flex-grow: 1;

      .list-pull-refresh-view {
        width: 100%;

        :deep(.van-cell) {
          height: 106px;
          background-color: transparent;
          padding: 0 16px;
          margin-bottom: 16px;
          background-color: #E6E6E6;
          border-radius: 8px;

          .van-cell__value {
            width: calc(98% - 32px);
          }

          .evaluation_item {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .van-image {
              img {
                width: 48px;
                height: 48px;
              }
            }

            .evaluation_info {
              width: calc(100% - 64px);
              color: #000000;
              font-weight: 600;
              text-align: left;
              margin-left: 16px;

              .title {
                width: 100%;
                font-size: 14px;
                word-wrap: break-word;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .space_line {
                width: 100%;
                height: 1px;
                margin: 4px 0;
                background: rgba(36, 36, 36, 0.20);
              }

              .time {
                font-size: 12px;
              }
            }
          }
        }

        :deep(.van-cell:active) {
          opacity: 0.7;
        }

        :deep(.van-cell:after) {
          border-bottom: 0;
        }
      }
    }
  }

}
</style>