// import Layout from "@/layout/index.vue";
import type { RouteRecordRaw } from "vue-router";
import Home from "../views/home.vue";
import { NavigatorRoute } from "@/utils/const";

const routes: Array<RouteRecordRaw> = [
  {
    //使用正则的方式，匹配任意的
    path: "/:pathMatch(.*)",
    component: Home,
  },
];

export default routes;
