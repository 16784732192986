import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c0335642"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "chat_lottie_icon",
  ref: "chatLottieAnRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_button = _resolveComponent("van-button")!

  return (_openBlock(), _createBlock(_component_van_button, {
    disabled: _ctx.disabled,
    onClick: _ctx.handleClick
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, null, 512),
      _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
    ]),
    _: 1
  }, 8, ["disabled", "onClick"]))
}