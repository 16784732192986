<template>
  <div class="evaluation_list_view">
    <van-dialog
      :show="show && evaluationList.length > 0 && evaluationStatus == 1"
      :show-confirm-button="false"
    >
      <div class="evaluation_list">
        <button class="close_btn" @click="closeBtnClick">
          <img :src="require('../assets/image/close_black_icon.svg')" />
        </button>
        <van-list style="overflow-y: scroll">
          <van-cell v-for="(item, index) in evaluationList" :key="index">
            <div class="evaluation_list_item">
              <span class="item_title">{{ item.title }}</span>
              <div class="space_line"></div>
              <div class="evaluation_info">
                <span class="desc" v-html="item.desc"></span>
                <button
                  :class="[
                    'action_button',
                    item.done ? 'reset_evaluation' : '',
                  ]"
                  @click="actionButtonClick(item.id)"
                >
                  {{ item.done ? "重新测评" : "去测评" }}
                </button>
              </div>
            </div>
          </van-cell>
        </van-list>
      </div>
    </van-dialog>
    <div class="topic">
      <van-dialog
        :show="show && evaluationStatus == 2"
        :show-confirm-button="false"
      >
        <div class="topic_bg">
          <van-button class="exit_btn" @click="exitButtonClick">
            <img
              :src="require('../assets/image/close_white_icon.svg')"
              class="exit_img"
            />
            <span class="exit_tips">退出</span>
          </van-button>
          <div class="topic_content">
            <span class="topic_tips">请选择</span>
            <span class="topic_title">{{
              currentScaleQA.sort +
              "/" +
              scaleQuestionList.length +
              ". " +
              currentScaleQA.title
            }}</span>
            <div class="space_line"></div>
            <ul class="answer_list">
              <li v-for="(item, index) in currentScaleQA.answer">
                <button
                  :class="[
                    'answer_button',
                    answerOption == item.option ? 'answer_button_seleted' : '',
                  ]"
                  @click="answerButtonClick(item.option)"
                >
                  {{ item.option + "." + item.content }}
                </button>
              </li>
            </ul>
            <van-button
              v-if="currentScaleQA.sort == scaleQuestionList.length"
              :disabled="!answerOption || answerSubmiting"
              class="next_button submit_button"
              @click="nextButtonClick"
            >
              <span class="submit_tips">提交</span>
            </van-button>

            <van-button
              v-else
              :disabled="!answerOption || answerSubmiting"
              class="next_button"
              @click="nextButtonClick"
            >
              <img
                :src="require('../assets/image/next_white_icon.svg')"
                class="next_icon"
              />
            </van-button>
          </div>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script lang="ts">
import { showDialog, showToast } from "vant";
import { defineComponent, ref, onMounted, Ref, watch } from "vue";
import { ScaleQuestionItem, linkardNet } from "@/net/linkardNet";
import { HttpResponse } from "@/net/http";
import { useUserStore } from "@/stores/user";
import { uuid } from "vue3-uuid";

export default defineComponent({
  props: {
    id: {
      type: String,
      default: "",
    },
    evaluationList: {
      type: Array as () => Array<any>,
      default: [],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    // 1:题目列表 2:进入答题:3 答题间隔，不显示题目
    const evaluationStatus = ref(1);
    const scaleQuestionList: Ref<ScaleQuestionItem[]> = ref([]);
    let progressScaleId = "";
    let progressRoundId = "";
    const answerOption = ref("");
    const currentScaleQA: Ref<ScaleQuestionItem> = ref({
      title: "",
      type: "",
      sort: -1,
      answer: [],
    });

    let seconds = 2;
    let timerInterval = 0;
    const answerSubmiting = ref(false);

    let startSpeakInterval = 0;
    let startSpeakSeconds = 5;

    let endSpeakInterval = 0;
    let endSpeakSeconds = 7;

    const userStore = useUserStore();

    const closeBtnClick = () => {
      closeEvaluationView();
    };

    const voicePlayTextRequest = (
      text: string,
      start: boolean,
      finish: boolean
    ) => {
      emit("voicePlayText", text, start, finish);
    };

    const gotoStartEvaluation = (scaleId: string) => {
      if (scaleId) {
        actionButtonClick(scaleId);
      }
    };

    const actionButtonClick = (scaleId: string) => {
      if (evaluationStatus.value == 3) return;
      progressScaleId = scaleId;
      progressRoundId = uuid.v4();
      evaluationStatus.value = 3;
      voicePlayTextRequest("好的，我们开始测评吧！", true, false);
      startSpeakCountDown();
      // getScaleListRequest(progressScaleId);
    };

    const evaluationStartPlayEnd = () => {
      if (evaluationStatus.value == 1) return;
      if (currentScaleQA.value.sort >= scaleQuestionList.value.length) {
        clearEndSpeakupdateTimer();
        closeEvaluationView();
      } else {
        clearStartSpeakupdateTimer();
        if (!progressScaleId) return;
        getScaleListRequest(progressScaleId);
      }
    };

    const exitButtonClick = () => {
      showDialog({
        message: "您尚未完成测评，退出后已作答数据降丢失，请确认操作",
        showCancelButton: true,
        confirmButtonText: "退出",
        cancelButtonText: "取消",
      }).then(() => {
        closeEvaluationView();
      });
    };

    const closeEvaluationView = () => {
      emit("exitEvaluationView");
      evaluationStatus.value = 1;
      progressScaleId = "";
      progressRoundId = "";
      answerOption.value = "";
      currentScaleQA.value = {
        title: "",
        type: "",
        sort: -1,
        answer: [],
      };
      startSpeakSeconds = 5;
      endSpeakSeconds = 7;
      resetTimerInterval();
    };

    const answerButtonClick = (option: string) => {
      if (!answerOption.value) {
        answerOption.value = option;
        startCountDown();
      } else {
        answerOption.value = option;
      }
    };

    const getScaleListRequest = (scaleId: string) => {
      linkardNet.scaleQuestion(userStore.linkardId, scaleId).then(
        (response) => {
          scaleQuestionList.value = response;
          setCurrentScaleQA(scaleQuestionList.value[0]);
          evaluationStatus.value = 2;
        },
        (ex) => {
          const error = ex as HttpResponse<any>;
          showToast(error.message);
        }
      );
    };

    const setCurrentScaleQA = (item: ScaleQuestionItem) => {
      currentScaleQA.value = item;
      voicePlayTextRequest(
        `第${currentScaleQA.value.sort}题.${currentScaleQA.value.title}`,
        false,
        false
      );
    };

    const submitScaleAnswerRequest = () => {
      if (evaluationStatus.value == 3) return;
      answerSubmiting.value = true;
      const args = {
        linkardId: userStore.linkardId,
        scaleId: progressScaleId,
        roundId: progressRoundId,
        questionSort: currentScaleQA.value.sort,
        answerOption: answerOption.value,
      };
      linkardNet.submitScaleAnswer(args).then(
        (response) => {
          answerSubmiting.value = false;
          //最后一题
          if (currentScaleQA.value.sort >= scaleQuestionList.value.length) {
            answerOption.value = "";
            createScaleReportRequest();
            evaluationStatus.value = 3;
            voicePlayTextRequest(
              "您已完成本次测评，正在生成报告，请稍后",
              false,
              true
            );
            endSpeakCountDown();
          } else {
            setCurrentScaleQA(
              scaleQuestionList.value[currentScaleQA.value.sort]
            );
            answerOption.value = "";
          }
        },
        (ex) => {
          answerSubmiting.value = false;
          const error = ex as HttpResponse<any>;
          showToast(error.message);
        }
      );
    };

    const createScaleReportRequest = () => {
      const args = {
        linkardId: userStore.linkardId,
        scaleId: progressScaleId,
        roundId: progressRoundId,
      };
      linkardNet.createScaleReport(args).then(
        (response) => {
          emit("setReportId", response);
        },
        (ex) => {
          const error = ex as HttpResponse<any>;
          showToast(error.message);
        }
      );
    };

    const startCountDown = () => {
      if (seconds > 0) {
        timerInterval = setInterval(updateTimer, 1000);
      }
    };

    const updateTimer = () => {
      if (seconds > 0) {
        seconds--;
      } else {
        resetTimerInterval();
        submitScaleAnswerRequest();
      }
    };

    const nextButtonClick = () => {
      if (!answerOption.value) {
        showToast("请选择选项");
        return;
      }

      resetTimerInterval();
      submitScaleAnswerRequest();
    };

    const resetTimerInterval = () => {
      clearInterval(timerInterval);
      timerInterval = 0;
      seconds = 2;
    };

    //如果播放开始的话术语，播放完成没有回调，则执行倒计时
    const startSpeakCountDown = () => {
      if (startSpeakSeconds > 0) {
        startSpeakInterval = setInterval(startSpeakUpdateTimer, 1000);
      }
    };

    const startSpeakUpdateTimer = () => {
      if (startSpeakSeconds > 0) {
        startSpeakSeconds--;
      } else {
        clearStartSpeakupdateTimer();
        getScaleListRequest(progressScaleId);
      }
    };

    const clearStartSpeakupdateTimer = () => {
      clearInterval(startSpeakInterval);
      startSpeakInterval = 0;
      startSpeakSeconds = 5;
    };

    //如果播放结束的话术语，播放完成没有回调，则执行倒计时
    const endSpeakCountDown = () => {
      if (endSpeakSeconds > 0) {
        endSpeakInterval = setInterval(endSpeakUpdateTimer, 1000);
      }
    };

    const endSpeakUpdateTimer = () => {
      if (endSpeakSeconds > 0) {
        endSpeakSeconds--;
      } else {
        clearEndSpeakupdateTimer();
        closeEvaluationView();
      }
    };

    const clearEndSpeakupdateTimer = () => {
      clearInterval(endSpeakInterval);
      endSpeakInterval = 0;
      endSpeakSeconds = 7;
    };

    onMounted(() => {});

    return {
      evaluationStatus,
      currentScaleQA,
      scaleQuestionList,
      answerOption,
      answerSubmiting,
      closeBtnClick,
      actionButtonClick,
      exitButtonClick,
      answerButtonClick,
      nextButtonClick,
      evaluationStartPlayEnd,
      gotoStartEvaluation,
    };
  },
});
</script>

<style lang="less" scoped>
.evaluation_list_view {
  pointer-events: auto;

  :deep(.van-dialog) {
    border-radius: 0px;
    background-color: transparent;
    padding: 16px;
    max-width: 100vw;
    width: 100vw;
    margin: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .evaluation_list {
    width: 335px;
    max-height: 330px;
    background-color: #e6e6e6;
    padding: 30px 0 10px 0;
    border-radius: 28px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .close_btn {
      position: absolute;
      top: -16px;
      right: -16px;
      width: 40px;
      height: 40px;
      border-width: 0;
      border-radius: 20px;
      background-color: #f0f0f0;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 13px;
        height: 13px;
      }
    }

    .close_btn:active {
      opacity: 0.6;
    }

    :deep(.van-list) {
      .van-cell {
        border-width: 0px;
        border-radius: 16px;
        margin-bottom: 24px;
        background-color: #f0f0f0;
      }
    }

    .evaluation_list_item {
      margin: 6px 0;
      width: 270px;
      height: auto;
      font-size: 12px;
      color: #000000;
      text-align: left;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      font-weight: 600;

      .item_title {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        margin-top: 4px;
        width: 100%;
      }

      .space_line {
        width: 100%;
        height: 1px;
        background-color: #242424;
        margin: 16px 0;
      }

      .evaluation_info {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        .desc {
          text-align: left;
          color: #000000;
          font-size: 10px;
          line-height: 14px;
        }

        .action_button {
          width: 72px;
          height: 34px;
          font-size: 10px;
          flex-grow: 1;
          max-width: 80px;
          background-color: #f3ec3f;
          border: 1px solid #242424;
          border-radius: 20px;
        }

        .action_button:active {
          opacity: 0.6;
        }

        .reset_evaluation {
          color: #f0f0f0;
          background-color: #242424;
        }
      }
    }
  }

  .topic {
    :deep(.van-dialog) {
      top: auto;
      bottom: 10px;
      transform: none;
      transition: none;

      border-radius: 0px;
      background-color: transparent;

      .van-dialog__footer {
        margin-bottom: 0;
      }
    }

    .topic_bg {
      width: 335px;
      color: #000000;
      font-size: 12px;
      background-color: #e6e6e6;
      border-radius: 28px;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      .exit_btn {
        width: 73px;
        height: 34px;
        border-radius: 32px;
        border: none;
        padding: 0;
        pointer-events: auto;
        background-color: #242424;
        position: absolute;
        top: 16px;
        right: 16px;

        :deep(.van-button__text) {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .exit_img {
            width: 13px;
            height: 13px;
          }

          .exit_tips {
            color: #f0f0f0;
            text-align: center;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 14px;
            margin-left: 6px;
          }
        }
      }

      .topic_content {
        width: calc(100% - 96px);
        height: auto;
        background-color: #f0f0f0;
        border-radius: 16px;
        padding: 24px 32px 10px 32px;
        font-weight: 600;
        margin: 66px 0 20px 0;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .topic_tips {
          width: 100%;
          font-size: 10px;
          text-align: left;
          margin-bottom: 4px;
        }

        .topic_title {
          width: 100%;
          text-align: left;
          font-size: 14px;
          line-height: 18px;
        }

        .space_line {
          width: 100%;
          height: 1px;
          background-color: #242424;
          margin: 16px 0;
        }

        .answer_list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
          margin: 0;

          li {
            width: 100%;

            .answer_button {
              width: 100%;
              height: 26px;
              line-height: 26px;
              border: 1px solid #242424;
              padding: 0 10px;
              text-align: left;
              margin-bottom: 8px;
              background-color: #f0f0f0;
              border-radius: 13px;
              font-size: 10px;

              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
            }

            .answer_button:active {
              opacity: 0.6;
            }

            .answer_button_seleted {
              background-color: #fff000;
            }
          }

          // li:nth-child(2n) {
          //   .answer_button {
          //     margin: 4px 16px 4px 0;
          //   }
          // }

          // /* 设置前两个li元素的上边距 */
          // li:nth-of-type(-n+2) {
          //   margin-top: 0px;
          // }
        }
      }

      .next_button {
        background-color: #4596f5;
        width: 40px;
        height: 40px;
        border-width: 0;
        border-radius: 20px;
        margin: 16px 0 10px 0;

        .next_icon {
          width: 10px;
          height: 20px;
        }

        .submit_tips {
          font-size: 12px;
          color: #f0f0f0;
        }
      }

      .submit_button {
        width: 70px;
      }
    }
  }

  :deep(.van-overlay) {
    pointer-events: auto;
    background-color: transparent;
  }
}
</style>
