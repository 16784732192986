import { uuid } from "vue3-uuid";
import { DEVICE_ID } from "./const";

const { userAgent } = navigator;

export type BuryingPointDataType = {
  os_version: string;
  os: string;
  device_id: string;
  screen_height: number;
  screen_width: number;
  browser: string;
  browser_version: string;
  url: string;
  //   url_path: string;
  title: string;
  time: string;
};

class BuryingPointData {
  _isVivo = () => {
    const ua = navigator.userAgent.toLowerCase();
    const isVivo = ua.match(/vivo/i);
    return isVivo;
  };

  getOS = (): string => {
    const isIOSWeb = /(iPhone|iPad|iPod|iOS)/i.test(userAgent); // ios终端
    const isMacWeb = /(mac|Mac|macOS|OSX|MacOS)/i.test(userAgent); // Mac终端
    const isAndroidWeb = /(Android|android)/i.test(userAgent); // android终端
    const isWindowsWeb = /(win|Win|windows|Window|Windows)/i.test(userAgent); // windows终端

    let osName = "Unknown OS";

    switch (true) {
      case /(iPhone|iPad|iPod|iOS)/i.test(userAgent):
        osName = "iOS";
        break;
      case /(mac|Mac|macOS|OSX|MacOS)/i.test(userAgent):
        osName = "Mac OS";
        break;
      case /(Android|android)/i.test(userAgent) || this._isVivo():
        osName = "Android";
        break;
      case /(win|Win|windows|Window|Windows)/i.test(userAgent):
        osName = "Windows";
        break;
    }
    return osName;
  };

  getOSVersion = (): string => {
    let match = userAgent.match(/Windows NT ([\d.]+)/);
    if (match) return match[1];

    match = userAgent.match(/Mac OS X ([\d_]+)/);
    if (match) return match[1].replace(/_/g, ".");

    match = userAgent.match(/Android ([\d.]+)/);
    if (match) return match[1];

    match = userAgent.match(/CPU (iPhone )?OS ([\d_]+)/);
    if (match) return match[2].replace(/_/g, ".");

    return "Unknown";
  };

  getBrowserInfo = (): {
    browser: string;
    browserVersion: string;
  } => {
    let match = userAgent.match(
      /(MSIE|Trident\/|Edge\/|Edg\/|Firefox\/|Chrome\/|Safari\/|Opera\/|OPR\/|Vivaldi\/)([\d.]+)/
    );
    if (!match) return { browser: "Unknown", browserVersion: "Unknown" };

    let browser = "Unknown";
    let version = "Unknown";
    if (match.length >= 2) {
      browser = match[1];
      version = match[2];
      switch (browser) {
        case "MSIE":
        case "Trident/":
          browser = "Internet Explorer";
          break;
        case "Edge/":
        case "Edg/":
          browser = "Edge";
          break;
        case "Firefox/":
          browser = "Firefox";
          break;
        case "Chrome/":
          browser = "Chrome";
          break;
        case "Safari/":
          browser = "Safari";
          break;
        case "Opera/":
        case "OPR/":
          browser = "Opera";
          break;
        case "Vivaldi/":
          browser = "Vivaldi";
          break;
      }
    }

    return { browser, browserVersion: version };
  };

  GetBuryingPointDataInfo = (): BuryingPointDataType => {
    let deviceId = localStorage.getItem(DEVICE_ID);
    if (!deviceId || deviceId == undefined || deviceId == "undefined") {
      deviceId = uuid.v4();
      localStorage.setItem(DEVICE_ID, deviceId);
    }
    const data: BuryingPointDataType = {
      os_version: this.getOSVersion(),
      os: this.getOS(),
      device_id: deviceId,
      screen_height: window.screen.height,
      screen_width: window.screen.width,
      browser: this.getBrowserInfo().browser,
      browser_version: this.getBrowserInfo().browserVersion,
      time: new Date().toLocaleString(),
      title: document.title,
      url: document.location.href,
    };

    return data;
  };

  chatRequestId: string | undefined;
  chatId: string | undefined;
  linkardName: string | undefined;
}

export const buryingPointData = new BuryingPointData();
