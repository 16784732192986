export const ACCESS_TOKEN = "token";

export const DEVICE_ID = "deviceId";

export const WX_APP_ID = "wx5e495e64aa176a5a";

export const WX_SHARE_LOGO = "https://linkard.me/logo.jpg";

/***********路由名称***********/
export enum NavigatorRoute {
  //首页
  HOME_PAGE = "home",
}
