<template>
  <div class="chat_conent_bg">
    <div :class="[isUmi ? 'identity_view_umi' : 'identity_view_mine']"></div>
    <div class="message_container_bg">
      <span class="title_name">{{ isUmi ? npcName : "YOU" }}</span>
      <div v-if="triggerType == -1" class="chat-content">
        <div class="message_content">
          <span ref="mathjaxRef" v-html="text ? text : ''"></span>
        </div>
        <!-- <img v-if="fromAudio" class="from_audio_img"
                    :src="require('../assets/image/chat_message_from_audio.png')" /> -->
      </div>
      <div v-else class="trigger_chat_content">
        <div
          v-if="triggerType == 2 || triggerType == 4"
          :style="{
            width: imgWidth + 'rem',
            height: imgHeight + 'rem',
            backgroundImage: 'url(' + thumbnailImg + ')',
          }"
          class="trigger_chat_img"
          @click="clickImg"
        ></div>
        <div
          v-else-if="triggerType == 3 || triggerType == 5"
          class="trigger_chat_video"
          :style="{
            width: imgWidth + 'rem',
            height: imgHeight + 'rem',
            backgroundImage: 'url(' + thumbnailImg + ')',
          }"
          @click="clickVideo"
        >
          <div v-if="triggerType == 3 || triggerType == 5" class="play_icon_bg">
            <img
              src="../assets/image/trigger_play.png"
              alt=""
              class="trigger_play_icon"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { linkardNet } from "@/net/linkardNet";
import { showImagePreview } from "vant";
import { defineComponent, nextTick, onMounted, ref, watch } from "vue";

export default defineComponent({
  name: "ChatMessageListItem",
  props: {
    text: {
      type: String,
      default: "",
    },
    mediaUrl: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    npcName: {
      type: String,
      default: "",
    },
    fromAudio: {
      type: Boolean,
      default: false,
    },
    isUmi: {
      type: Boolean,
      default: false,
    },
    sourceType: {
      type: Number,
      default: -1,
    },
    triggerType: {
      type: Number,
      default: -1,
    },
  },
  setup(props, { emit }) {
    let sizeRegex = /_mediaSize_(\d+)_(\d+)_/;
    let naturalResources = "";
    let imgWidth = ref(0.0);
    let imgHeight = ref(0.0);
    let thumbnailImg = ref("");
    const mathjaxRef = ref<HTMLDivElement>();

    // MARK: methods

    onMounted(() => {
      if (props.mediaUrl) {
        splitUrl(props.mediaUrl);
      } else {
        var mediaWidth = 99.0;
        var mediaHeight = 176.0;
        imgWidth.value = mediaWidth / 37.5;
        imgHeight.value = mediaHeight / 37.5;
      }
    });

    const clickImg = async () => {
      if (props.triggerType == 4) {
        showImagePreview({
          images: [props.url],
          startPosition: 0,
          maxZoom: 8,
        });
        return;
      }
      if (props.sourceType == 1) {
        const originalMediaUrl = await getOriginalMediaUrlRequest(
          props.mediaUrl
        );
        showImagePreview({
          images: [originalMediaUrl ? originalMediaUrl : props.mediaUrl],
          startPosition: 0,
          maxZoom: 8,
        });
      } else {
        if (naturalResources) {
          showImagePreview({
            images: [naturalResources ? naturalResources : props.mediaUrl],
            startPosition: 0,
            maxZoom: 8,
          });
        }
      }
    };
    const clickVideo = async () => {
      if (props.triggerType == 5) {
        emit("showVideoPaly", props.url);
        return;
      }
      if (props.sourceType == 1) {
        const originalMediaUrl = await getOriginalMediaUrlRequest(
          props.mediaUrl
        );
        emit("showVideoPaly", originalMediaUrl);
      } else {
        if (naturalResources) {
          emit("showVideoPaly", naturalResources);
        }
      }
    };

    const getOriginalMediaUrlRequest = async (thumbnailUrl: string) => {
      const result = await linkardNet.getOriginalMediaUrl(thumbnailUrl);
      return result;
    };

    //判断连接
    //_mediaSize_100_200:100_200表示图片宽高
    const splitUrl = (url: string) => {
      extractWidthAndHeight(url);
      if (url.includes("_mediaSize_")) {
        naturalResources = url.replace(/_mediaSize_.*$/, "");
        thumbnailImg.value = url;
      } else {
        //适配旧版本
        if (props.triggerType == 2) {
          if (url.includes("_thumbnail")) {
            thumbnailImg.value = url;
            naturalResources = url.replace(/_thumbnail.*$/, "");
          } else {
            thumbnailImg.value = url;
            naturalResources = url;
          }
        } else if (props.triggerType == 3) {
          thumbnailImg.value = url + "_thumbnail.png";
          naturalResources = url;
        } else if (props.triggerType == 4) {
          thumbnailImg.value = props.mediaUrl;
          naturalResources = url;
        } else if (props.triggerType == 5) {
          thumbnailImg.value = props.mediaUrl;
          naturalResources = url;
        }
      }
    };

    //从链接中提取宽和高
    const extractWidthAndHeight = (url: string) => {
      var mediaWidth = 0.0;
      var mediaHeight = 0.0;
      //如果url是空，使用默认值
      if (!url) {
        if (props.triggerType == 2) {
          mediaWidth = 99.0;
          mediaHeight = 176.0;
        } else {
          mediaWidth = 176.0;
          mediaHeight = 99.0;
        }
        return;
      }

      // 使用正则表达式匹配URL中的宽高信息
      let matchRes = url.match(sizeRegex);
      // 如果匹配到宽高信息，返回匹配结果；否则返回默认值
      if (matchRes && matchRes.length === 3) {
        var tempImgWidth = parseInt(matchRes[1]);
        var tempImgHeight = parseInt(matchRes[2]);
        var aspectRatio = tempImgWidth / tempImgHeight;
        var maxSize = Math.max(tempImgWidth, tempImgHeight);
        var standardSize = 176;
        switch (true) {
          case maxSize < 260:
            standardSize = 140;
            break;
          case maxSize >= 260 && maxSize < 400:
            standardSize = 156;
            break;
          case maxSize >= 400 && maxSize < 500:
            standardSize = 166;
            break;
          case maxSize >= 500 && maxSize < 600:
            standardSize = 176;
            break;
          case maxSize >= 600 && maxSize < 700:
            standardSize = 186;
            break;
          case maxSize >= 700 && maxSize < 1080:
            standardSize = 196;
            break;
          case maxSize >= 1080 && maxSize < 1440:
            standardSize = 216;
            break;
          case maxSize >= 1440:
            standardSize = 226;
            break;
          default:
            standardSize = 176;
            break;
        }
        // if (this.triggerType == 2) {
        switch (true) {
          case aspectRatio < 1:
            mediaHeight = standardSize;
            mediaWidth = Math.max(mediaHeight * aspectRatio, 60);
            break;
          case aspectRatio > 1 && aspectRatio <= 4:
            mediaWidth = standardSize;
            mediaHeight = Math.max(mediaWidth / aspectRatio, 60);
            break;
          case aspectRatio > 4:
            mediaWidth = standardSize;
            mediaHeight = Math.min(mediaWidth * aspectRatio, 60);
            break;

          default:
            mediaHeight = standardSize;
            mediaWidth = standardSize;
            break;
          // }
        }
      } else {
        // 没有匹配到宽高信息时，返回默认值
        if (props.triggerType == 2) {
          mediaWidth = 99.0;
          mediaHeight = 176.0;
        } else {
          mediaWidth = 176.0;
          mediaHeight = 99.0;
        }
      }
      imgWidth.value = mediaWidth / 37.5;
      imgHeight.value = mediaHeight / 37.5;
    };

    watch(
      () => props.mediaUrl,
      (newValue, oldValue) => {
        if (newValue) {
          splitUrl(props.mediaUrl);
        }
      }
    );

    onMounted(() => {
      if (
        (props.text && props.text.includes("$")) ||
        props.text.includes("\\)") ||
        props.text.includes("\\]")
      ) {
        nextTick(() => {
          window.MathJax.typesetPromise([mathjaxRef.value]);
        });
      }
    });

    return {
      imgWidth,
      imgHeight,
      thumbnailImg,
      mathjaxRef,
      clickImg,
      clickVideo,
    };
  },
});
</script>

<style lang="less" scoped>
.chat_conent_bg {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .identity_view_mine {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-top: 2px;
    background-color: #f3ec3f;
  }

  .identity_view_umi {
    width: 8px;
    height: 8px;
    margin-top: 3px;
    border-radius: 4px;
    background-color: rgba(36, 36, 36, 0.2);
  }

  .message_container_bg {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 8px;

    .title_name {
      color: #242424;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
    }

    .chat-content {
      width: auto;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      .message_content {
        font-size: 12px;
        padding: 4px 18px 16px 0px;
        color: #242424;
        font-weight: 500;
        text-align: left;
        line-height: 18px;
        word-break: break-all;
        width: 270px;

        .loading_img {
          height: 16px;
          width: 25.6px;
        }
      }

      .from_audio_img {
        width: 20px;
        height: 16px;
        margin: 12px 14px 0 -10px;
      }
    }

    .trigger_chat_content {
      width: auto;
      height: auto;

      .trigger_chat_img {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        border: 2px solid #f0f0f0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 4px 18px 16px 0px;
      }

      .trigger_chat_video {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        border: 2px solid #f0f0f0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 4px 18px 16px 0px;
      }

      .play_icon_bg {
        width: 40px;
        height: 40px;
        background-color: rgba(240, 240, 240, 0.7);
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        pointer-events: none;

        .trigger_play_icon {
          width: 13px;
          height: 15px;
          margin-left: 2px;
        }
      }
    }
  }
}

.slide-item {
  animation-name: slidedown;
  animation-fill-mode: forwards;
  animation-duration: 1000ms;
  opacity: 0;
}

@keyframes slidedown {
  0% {
    transform: translateY(-80%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
</style>
<style>
mjx-merror[data-mjx-error] {
  background: inherit;
  /* color: inherit; */
}

/*
mjx-container mjx-merror[data-mjx-error]::before,
mjx-container mjx-merror[data-mjx-error]::after {
    content: '$';
    font-family: initial
}

mjx-container[display] mjx-merror[data-mjx-error]::before,
mjx-container[display] mjx-merror[data-mjx-error]::after {
    content: '$$';
    font-family: initial
} */
</style>
