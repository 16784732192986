<template>
  <div class="umi_audio_listen_loading" ref="umiAudioListenRef"></div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import lottie, { AnimationItem } from 'lottie-web';
import loadingAnimationData from "../assets/image/umi_audio_listen_loading.json";

export default defineComponent({
  setup() {
    const umiAudioListenRef = ref();
    let umiAudioListenAn: AnimationItem | null;
    onMounted(() => {
      const options = {
        animationData: loadingAnimationData,
        loop: true,
        autoplay: true
      };

      umiAudioListenAn = lottie.loadAnimation({
        container: umiAudioListenRef.value,
        renderer: 'svg',
        ...options
      });
    });

    onBeforeUnmount(() => {
      if (umiAudioListenAn) {
        umiAudioListenAn.stop();
        umiAudioListenAn.destroy();
        umiAudioListenAn = null;
      }
    });

    return {
      umiAudioListenRef,
    };
  }
});
</script>

<style lang="less" scoped>
.umi_audio_listen_loading {
  width: 26px;
  height: 26px;
}
</style>
