import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40f645ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top_action_and_title" }
const _hoisted_2 = { class: "special_subject_list_bg" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "special_subject_name" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "post-link-name" }
const _hoisted_8 = { class: "custom_linkard_name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_image = _resolveComponent("van-image")!
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", {
    class: "cabinet_bg",
    style: _normalizeStyle({ backgroundImage: `url(${_ctx.backgroundImg})` })
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.cabinetId || _ctx.cabinetId == 'undefined')
        ? (_openBlock(), _createBlock(_component_van_image, {
            key: 0,
            class: "back_action",
            src: require('../assets/image/white_btn_icon.svg'),
            onClick: _ctx.toMyLinkard
          }, null, 8, ["src", "onClick"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(['cabinet_name', _ctx.cabinetId && _ctx.cabinetId != 'undefined' ? 'cabinet_name_center' : ''])
      }, _toDisplayString(_ctx.cabinetInfo?.cabinetName), 3)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cabinetInfo?.specialTopicList, (specialTopItem, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "special_subject_item_bg",
          key: index
        }, [
          _createElementVNode("img", {
            class: "special_subject_img",
            src: specialTopItem.url,
            alt: ""
          }, null, 8, _hoisted_3),
          _createElementVNode("span", _hoisted_4, _toDisplayString(specialTopItem.name), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(specialTopItem.linkardList, (linkardInfo, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "post-wrapper",
              key: 'item_' + index
            }, [
              _createElementVNode("div", {
                id: "posterHtml",
                onClick: ($event: any) => (_ctx.linkardItemClick(linkardInfo))
              }, [
                _createElementVNode("img", {
                  src: linkardInfo.avatar,
                  alt: "",
                  class: "post-link-logo"
                }, null, 8, _hoisted_6),
                _createElementVNode("div", _hoisted_7, _toDisplayString(linkardInfo.name), 1)
              ], 8, _hoisted_5),
              _createElementVNode("div", _hoisted_8, _toDisplayString(linkardInfo.showName), 1)
            ]))
          }), 128))
        ]))
      }), 128))
    ]),
    _withDirectives(_createVNode(_component_Loading, null, null, 512), [
      [_vShow, _ctx.listLoading]
    ])
  ], 4))
}