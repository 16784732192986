import { Base64 } from "js-base64";
//邮箱正则验证
export function validateEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

//手机号正则验证
export function validatePhone(number: string): boolean {
  const emailRegex = /(^1\d{10}$)|(^[0-9]\d{7}$)/;
  return emailRegex.test(number);
}

//手机号去掉区号
export function phoneWithOutReginPrefix(phoneNumber: string): string {
  let phoneNumberNoPrefix = "";
  if (phoneNumber.includes("+86,")) {
    phoneNumberNoPrefix = phoneNumber.replace("+86,", "");
  } else {
    phoneNumberNoPrefix = phoneNumber;
  }
  return phoneNumberNoPrefix;
}

//加密手机号
export function encryptPhoneNumber(phoneNumber: string): string {
  if (!phoneNumber) return "";
  let phoneNumberNoPrefix = "";
  if (phoneNumber.includes("+86,")) {
    phoneNumberNoPrefix = phoneNumber.replace("+86,", "");
  } else {
    phoneNumberNoPrefix = phoneNumber;
  }
  const digits = phoneNumberNoPrefix.match(/\d/g);
  if (digits && digits.length >= 5) {
    const encrypted =
      digits.slice(0, 3).join("") + "****" + digits.slice(-4).join("");
    return encrypted;
  } else {
    return "";
  }
}

export function encodeToBase64(str: string) {
  // 对字符串进行编码
  // var encode = encodeURIComponent(str);
  // 对编码的字符串转化base64
  return Base64.encode(str); //这一行就可以字符串转base64了
}
