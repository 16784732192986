<template>
  <div class="media-container">
    <div class="media-head">
      <div class="head_content">
        <span class="left_tips">LINKARD</span>
        <span>社媒聚合页</span>
        <div class="right_line"></div>
      </div>
      <van-image class="back_action" :src="require('../assets/image/white_btn_icon.svg')" @click="close"></van-image>
    </div>
    <div class="media-body" v-if="requestDone && linksListRes.length > 0">
      <div v-for="(item, index) in linksListRes" :key="index">
        <div class="media-item" :class="{ active: item.isTop }" @click="handleLink(item)">
          <van-image width="0.64rem" height="0.64rem" :src="item.icon" v-if="item.icon"></van-image>
          <span class="item-name">{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="media-empty" v-if="requestDone && linksListRes.length == 0">
      <van-image :src="require('../assets/image/icon-empty-dark.png')"></van-image>
      <span>暂无内容</span>
    </div>
  </div>
</template>

<script lang="ts">
import { ClickLinkArgs, SocialLinksListInfo, linkardNet } from '@/net/linkardNet';
import { defineComponent, ref, onMounted, Ref, watch } from 'vue';


const isValidUrl = (url: string): boolean => {
  var pattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
  return pattern.test(url);
}

export default defineComponent({

  props: {
    linkardId: {
      type: String,
      default: ''
    },
    linksList: {
      type: Array as () => SocialLinksListInfo[],
      default: () => []
    },
  },

  setup(props, { emit }) {

    let linksListRes: Ref<SocialLinksListInfo[]> = ref([]);
    let requestDone = ref(false);

    const close = () => {
      emit("closeMedia");
    };
    const handleLink = async (item: any) => {
      const args: ClickLinkArgs = {
        linkardId: props.linkardId,
        url: item.url
      };
      linkardNet.clickLink(args).then(
        (response) => {
          if (isValidUrl(item.url)) {
            window.location.href = item.url
          } else {
            socialLinksListRequest();
          }
        },
        (ex) => {

        },
      );
    };
    // 获取媒体聚合
    const socialLinksListRequest = async () => {

      linkardNet.socialLinksList(props.linkardId).then(
        (response) => {
          linksListRes.value = response;
          requestDone.value = true;
        },
        (ex) => {

        },
      );
    };

    onMounted(() => {
      if (props.linksList.length <= 0) {
        socialLinksListRequest();
      }
    });

    watch(() => props.linksList, (newValue, oldValue) => {
      if (newValue.length > 0) {
        linksListRes.value = props.linksList;
        requestDone.value = true;
      }
    });

    return {
      requestDone,
      linksListRes,
      close,
      handleLink
    }
  }
});
</script>

<style lang="less" scoped>
.media-container {
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2001;

  background: rgb(240, 240, 240);
  width: calc(100% - 20px);
  height: calc(100% - 32px);
  border: 2px solid #242424;
  border-radius: 28px;
  margin: 16px 10px;


  .media-head {
    left: 0;
    top: 10px;
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    position: absolute;

    .back_action {
      width: 48px;
      height: 48px;
      position: absolute;
      left: 10px;
      top: 40px;
    }

    .head_content {
      font-size: 12px;
      color: #242424;
      display: flex;
      font-weight: 700;
      align-items: center;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .left_tips {
        position: absolute;
        left: 20px;
      }

      .right_line {
        position: absolute;
        right: 20px;
        height: 1px;
        width: 40px;
        background-color: #242424;
      }
    }
  }

  .media-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: calc(100% - 140px);
    margin-top: 60px;
    overflow: scroll;

    .media-item {
      position: relative;
      margin: auto;
      color: #fff;
      width: 217px;
      height: 40px;
      line-height: 40px;
      background-color: #242424;
      border-radius: 40px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      padding: 0 10px;

      .van-image {
        margin-right: 6px;
        flex-shrink: 0;
      }

      .item-name {
        margin-right: 10px;
        font-size: 12px;
        font-weight: 500;
        display: inline;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;
      }
    }

    .active {
      border-radius: 30px;
      border: 1px solid #242424;
      background: #f3ec3f;
      color: #242424;
    }
  }

  .media-empty {
    margin: auto;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: start;
    align-items: center;

    :deep(.van-image) {
      width: 100px;
    }

    span {
      margin-top: 12px;
    }
  }
}
</style>