import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-81a9be6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "center_time_info"
}
const _hoisted_2 = {
  class: "umi_audio_lottie",
  ref: "chatLottieAnLeftRef"
}
const _hoisted_3 = {
  class: "umi_audio_lottie",
  ref: "chatLottieAnRightRef"
}
const _hoisted_4 = {
  key: 1,
  class: "listen_tips"
}
const _hoisted_5 = { style: {"text-align":"center"} }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_button = _resolveComponent("van-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['umi_audio_info_bg', _ctx.speakInfoSendState === 3 || _ctx.speakInfoSendState === 4 ? 'umi_audio_info_speaking_bg' : ''])
  }, [
    (_ctx.speakInfoSendState === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, null, 512),
          _createElementVNode("span", null, _toDisplayString(_ctx.formattedTime), 1),
          _createElementVNode("div", _hoisted_3, null, 512)
        ]))
      : (_ctx.speakInfoSendState === 3 || _ctx.speakInfoSendState === 4)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.umiName) + "正在" + _toDisplayString(_ctx.speakInfoSendState === 3 ? '思考' : '说话') + "中", 1),
            _createVNode(_component_van_button, {
              class: "close_button",
              onClick: _withModifiers(_ctx.closeUmiSpeak, ["stop"])
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: require('@/assets/image/umi_listen_close.svg'),
                  class: "close_button_img"
                }, null, 8, _hoisted_6)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true)
  ], 2))
}