<template>
  <div class="card-container" :style="{ backgroundImage: `url(${backgroundImg})` }">
    <div class="message_board">
      <div class="head_content">
        <span class="left_tips">LINKARD</span>
        <span>留言</span>
        <div class="right_line"></div>
      </div>
      <van-image class="back_action" :src="require('../assets/image/white_btn_icon.svg')"
        @click="backToLinkard"></van-image>
      <div class="message_board_box">
        <div class="empty-card" v-if="isOwn && messageBoardList.length == 0 && !listLoading">
          <van-image :src="require('../assets/image/icon-empty-dark.png')"></van-image>
          <div class="empty-card-text">
            <div>暂无内容</div>
          </div>
        </div>
        <van-pull-refresh v-if="isOwn && messageBoardList.length != 0" class="list-pull-refresh-view"
          v-model="lisrefreshing" @refresh="listOnRefresh">
          <van-list loading_text="加载中..." v-model:loading="listLoading" :finished="listFinished" @load="listOnLoad"
            finished_text="">
            <van-grid class="message_board_grid" :column-num="1" :border="false">
              <van-grid-item class="message_board_item" v-for="(messageItem, index) in messageBoardList" :key="index">
                <div class="message_board_item_bg">
                  <div class="user_info_bg">
                    <img class="user_head" :src="messageItem.headImg" />
                    <span class="user_name">{{ messageItem.name }}</span>
                    <van-image v-if="messageItem.versionIcon" class="permission_type"
                      :src="require('../assets/image/' + messageItem.versionIcon)"></van-image>
                  </div>
                  <div class="message_content_bg">
                    <div class="message_content_text_bg">
                      <span>{{ messageItem.message }}</span>
                    </div>

                  </div>
                  <span class="message_time_style">{{ messageItem.messageTime }}</span>
                  <van-button v-if="messageItem.canGotoCabinet" class="go_to_linkard" @click="goToLinkard(messageItem)">
                    前往访问
                  </van-button>
                </div>
              </van-grid-item>
            </van-grid>
          </van-list>
        </van-pull-refresh>

        <div v-else-if="!isOwn" class="leave_message_bg">
          <div class="leave_message_content">
            <van-field ref="inputfield" v-model="message" rows="12" type="textarea" autosize placeholder="请输入留言..."
              enterkeyhint="send" @focus="inputFocus" @keydown="handleKeyboard" />
          </div>
          <van-button class="send_message_button" @click="sendMessageBoardRequest">
            发送
          </van-button>
        </div>
      </div>
    </div>
    <Loading v-show="listLoading" />
  </div>
</template>

<script lang="ts">
import Loading from './loading.vue';
import { defineComponent, ref, onMounted, Ref } from 'vue';
import { showToast } from 'vant';
import { LeaveMessageItem, SendMessageArgs, linkardNet } from '@/net/linkardNet';
import { timeStampToDate } from '@/utils/dateUtils';

export default defineComponent({
  components: {
    Loading
  },
  props: {
    linkardId: {
      type: String,
      default: '',
    },
    isOwn: {
      type: Boolean,
      default: '',
    }
  },
  setup(props, { emit }) {

    let lisrefreshing = ref(false);
    let listLoading = ref(false);
    let listFinished = ref(false);
    let messageBoardList: Ref<LeaveMessageItem[]> = ref([]);
    let message = ref("");
    let pageNum = ref(1);

    const listOnRefresh = () => {
      messageBoardListRequest(true);
    };
    const listOnLoad = () => {
      messageBoardListRequest(false);
    };
    const backToLinkard = () => {
      emit('closeLeaveMessage');
    };
    const goToLinkard = async (item: LeaveMessageItem) => {
      emit('goToPathFromMessageBoard', item);
    };

    const handleKeyboard = async (event: KeyboardEvent) => {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    };
    const inputFocus = () => {

    };

    const messageBoardListRequest = async (pullDown: boolean) => {
      listLoading.value = true;
      if (pullDown) {
        pageNum.value = 1;
      }
      linkardNet.getMessageBoardList(pageNum.value, 10, props.linkardId).then(
        (response) => {
          lisrefreshing.value = false;
          listLoading.value = false;
          const tempInfoList = response.list;
          tempInfoList.forEach(item => {
            item.messageTime = timeStampToDate(item.createTime);
            switch (item.level) {
              case 1:
                item.versionIcon = 'message_trial_version.svg';
                if (item.path) {
                  item.canGotoCabinet = true;
                }
                break;

              case 2:
                item.versionIcon = 'message_home_premium.svg';
                if (item.path) {
                  item.canGotoCabinet = true;
                }
                break;

              case 3:
                item.versionIcon = 'message_professional_edition.svg';
                if (item.cabinetValid) {
                  item.canGotoCabinet = true;
                }
                break;

              case 4:
                item.versionIcon = 'message_enterprise_version.svg';
                if (item.cabinetValid) {
                  item.canGotoCabinet = true;
                }
                break;

            }
          });
          if (pullDown) {
            messageBoardList.value = tempInfoList;
          } else {
            messageBoardList.value = [...messageBoardList.value, ...tempInfoList];
          }
          if (tempInfoList.length >= 10) {
            pageNum.value += 1;
            listFinished.value = false;
          } else {
            listFinished.value = true;
          }

        },
        (ex) => {

        },
      );
    };
    const sendMessageBoardRequest = async () => {
      if (!message.value) {
        showToast('请输入留言内容');
        return;
      }
      listLoading.value = true;

      const args: SendMessageArgs = {
        linkardId: props.linkardId,
        message: message.value
      };

      linkardNet.sendMessageBoard(args).then(
        (response) => {
          listLoading.value = false;
          message.value = "";
          showToast('留言成功');
        },
        (ex) => {
          showToast('留言失败');
        },
      );
    }

    onMounted(() => {
      if (props.isOwn) {
        messageBoardListRequest(true);
      }
    });

    return {
      lisrefreshing,
      listLoading,
      listFinished,
      messageBoardList,
      message,
      sendMessageBoardRequest,
      backToLinkard,
      listOnRefresh,
      handleKeyboard,
      inputFocus,
      goToLinkard,
      listOnLoad,
      backgroundImg: require('../assets/image/default-body-bg.svg'),
    }
  }
});
</script>

<style lang="less" scoped>
.card-container {
  height: 100%;
  background: #242424;
  position: relative;
  overflow: hidden;


  .back_action {
    width: 48px;
    height: 48px;
    position: absolute;
    left: 26px;
    top: 65px;
  }

  .head_content {
    font-size: 12px;
    color: #242424;
    display: flex;
    font-weight: 700;
    align-items: center;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 56px;
    position: relative;

    .left_tips {
      position: absolute;
      left: 20px;
    }

    .right_line {
      position: absolute;
      right: 20px;
      height: 1px;
      width: 40px;
      background-color: #242424;
    }
  }

  .message_board {
    display: flex;
    flex-direction: column;
    background: rgb(240, 240, 240);
    height: calc(100% - 32px);
    border: 2px solid #242424;
    border-radius: 28px;
    margin: 16px 10px;

    .message_board_box {
      display: flex;
      margin-top: 20px;
      padding: 0 10px;
      flex-wrap: wrap;
      justify-content: space-between;
      height: calc(100% -240px);
      overflow-y: scroll;
      flex-grow: 1;
      margin-bottom: 20px;

      .empty-card {
        width: 100%;
        font-size: 16px;
        color: #f0f0f0;
        margin: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-content: start;
        align-items: center;
        bottom: 20px;

        .van-image {
          width: 100px;
        }

        .empty-card-text {
          margin-top: 12px;
          text-align: center;
          line-height: 24px;
          width: 100%;
          bottom: -20px;
          color: #242424;
          font-weight: 600;
        }
      }


      .message_board_item {
        width: 100%;
        height: auto;
        font-size: 12px;
        color: #242424;
        flex-basis: auto !important;
        --van-grid-item-content-background: transparent;
        --van-grid-item-content-padding: 6px;

        .message_board_item_bg {
          width: 100%;
          height: auto;
          padding: 10px 0 12px 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .user_info_bg {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;

            .user_head {
              width: 40px;
              height: 40px;
              border-radius: 20px;
            }

            .user_name {
              color: #000;
              font-size: 14px;
              font-weight: 600;
              margin: 0 4px 0 8px;
            }

            .permission_type {
              width: 20px;
              height: 20px;
            }
          }

          .message_content_bg {
            width: calc(100% - 44px);
            height: 86px;
            border-radius: 20px;
            border: 2px solid #242424;
            background-color: #F2F2F2;
            padding: 14px 20px 26px 20px;

            .message_content_text_bg {
              width: 100%;
              overflow-y: scroll;

              span {
                height: 86px;
                color: #000;
                font-size: 10px;
                font-weight: 600;
                line-height: 14px;
                text-align: left;
                display: block;
              }
            }
          }

          .message_time_style {
            width: 100%;
            color: #000;
            text-align: right;
            font-size: 10px;
            font-weight: 600;
            margin-top: 6px;
          }

          .go_to_linkard {
            height: 36px;
            border-radius: 32px;
            font-weight: 600;
            font-size: 12px;
            border: 2px solid #242424;
            background-color: #F3EC3F;
            position: absolute;
            left: 0;
            bottom: 12px;
          }
        }

      }


      .list-pull-refresh-view {
        width: 100%;

        .message_board_grid {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }

    .leave_message_bg {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .leave_message_content {
        width: calc(100% - 36px);
        height: 220px;
        border-radius: 20px;
        border: 2px solid #242424;
        background-color: #F2F2F2;

        :deep(.van-cell) {
          background-color: transparent;

          .van-field__control {
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            height: 100% !important;
            max-height: 100% !important;
          }
        }
      }

      .send_message_button {
        width: 88px;
        height: 36px;
        border-radius: 32px;
        font-weight: 600;
        font-size: 12px;
        border: 2px solid #242424;
        background-color: #F3EC3F;
        margin-top: 24px;
      }

    }
  }
}
</style>

<style>
.van-grid-item__content {
  padding: 0;
  background: transparent;
}
</style>
