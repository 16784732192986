export function getMonthName(month: number): string {
  switch (month) {
    case 1:
      return "Jan";
    case 2:
      return "Feb";
    case 3:
      return "Mar";
    case 4:
      return "Apr";
    case 5:
      return "May";
    case 6:
      return "Jun";
    case 7:
      return "Jul";
    case 8:
      return "Aug";
    case 9:
      return "Sep";
    case 10:
      return "Oct";
    case 11:
      return "Nov";
    case 12:
      return "Dec";
    default:
      return `${month}`;
  }
}

// 获取当前时间戳，单位秒
export function getTimestampInSeconds(): number {
  return Math.floor(Date.now() / 1000);
}

export function timeStampToDate(timestamp: number): string {
  // 创建一个Date对象，传入时间戳作为参数
  const date = new Date(timestamp);

  // 使用Date对象的方法获取年、月、日、小时、分钟、秒
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // 月份从0开始，所以要加1
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // 根据需要对月份、日期、小时、分钟、秒进行补零处理
  const formattedMonth = month < 10 ? "0" + month : month;
  const formattedDay = day < 10 ? "0" + day : day;
  const formattedHours = hours < 10 ? "0" + hours : hours;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

  // 构建时间字符串
  const formattedTime =
    year +
    "." +
    formattedMonth +
    "." +
    formattedDay +
    " " +
    formattedHours +
    ":" +
    formattedMinutes +
    ":" +
    formattedSeconds;

  return formattedTime;
}

//2023/03/04 14:58
export function getSlashDate(timestamp: number) {
  // 创建一个Date对象
  var date = new Date(timestamp);
  // 提取年、月、日、小时和分钟
  var year = date.getFullYear();
  var month = (date.getMonth() + 1).toString().padStart(2, "0"); // 月份从0开始，所以需要加1
  var day = date.getDate().toString().padStart(2, "0");
  var hour = date.getHours().toString().padStart(2, "0");
  var minute = date.getMinutes().toString().padStart(2, "0");
  return year + "-" + month + "-" + day + " " + hour + ":" + minute;
}
