<template>
  <div class="evaluation_detail_content">
    <div class="head_content">
      <span>我的评测报告-{{ detailInfo ? detailInfo.title : "--" }}</span>
      <span class="evaluation_time"
        >评测时间：{{ detailInfo ? detailInfo.createDate : "--|--" }}</span
      >
    </div>
    <van-image
      class="back_action"
      :src="require('../assets/image/white_btn_icon.svg')"
      @click="close"
    ></van-image>
    <div class="evaluation-detail-bg">
      <div class="empty-card" v-if="!detailInfo && !detialLoading">
        <van-image
          :src="require('../assets/image/icon-empty-dark.png')"
        ></van-image>
        <div class="empty-card-text">
          <div>评测数据暂未生成</div>
        </div>
      </div>
      <div class="evaluation-detail" v-if="detailInfo">
        <div class="top_info">{{ detailInfo.resultIntro }}</div>
        <div v-if="detailInfo.type == 'radar'" id="chart"></div>
        <div v-else class="bottom_info">
          <ul class="group_tab_bg">
            <li v-for="(item, index) in detailInfo.reportList">
              <div
                :class="[
                  'group_tab_item',
                  item.extra == '是'
                    ? cureentScaleDetail == item
                      ? 'group_tab_item_extra_selected'
                      : 'group_tab_item_extra'
                    : cureentScaleDetail == item
                    ? 'group_tab_item_selected'
                    : '',
                ]"
                @click="groupTabItemClick(index)"
              >
                {{ item.group }}
              </div>
            </li>
          </ul>
          <div class="space_line"></div>
          <div v-if="cureentScaleDetail" class="detail_text_bg">
            <span
              :class="[
                'result_title',
                cureentScaleDetail.extra == '是' ? 'result_title_extra' : '',
              ]"
              >【结果】{{ cureentScaleDetail.result }}</span
            >
            <span class="result_detail">{{ cureentScaleDetail.analysis }}</span>
            <span class="suggest_title">【建议】</span>
            <span class="suggest_detail">{{ cureentScaleDetail.advice }}</span>
          </div>
        </div>
        <div v-if="detailInfo.extraQRCode" class="qr_code_info">
          <span class="qr_code_title" v-html="detailInfo.extraTitle"></span>
          <img :src="detailInfo.extraQRCode" alt="" class="qr_code_img" />
        </div>
      </div>
    </div>
    <Loading v-show="detialLoading" />
    <div v-if="showRadarDetail" class="radar_detail_bg">
      <div class="radar_detail_click_view" @click="radarDetailCick"></div>

      <div
        v-if="cureentScaleDetail"
        class="detail_text_bg"
        :style="radarDetailStyle"
      >
        <div class="detail_text_content">
          <span class="result_title">{{ cureentScaleDetail.group }}</span>
          <span class="result_title">【结果】</span>
          <span class="result_detail">{{ cureentScaleDetail.analysis }}</span>
          <span class="suggest_title">【建议】</span>
          <span class="suggest_detail">{{ cureentScaleDetail.advice }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Ref, defineComponent, nextTick, onMounted, ref } from "vue";
import {
  linkardNet,
  ScaleDetailResponse,
  ScaleDetailItem,
} from "@/net/linkardNet";
import { HttpResponse } from "@/net/http";
import { showToast } from "vant";
import { useUserStore } from "@/stores/user";
import { getSlashDate } from "@/utils/dateUtils";
import ApexCharts from "apexcharts";

export default defineComponent({
  props: {
    evalReportId: {
      type: String,
      default: "",
    },
  },
  components: {
    ApexCharts,
  },
  setup(props, { emit }) {
    const userStore = useUserStore();
    const detailInfo: Ref<ScaleDetailResponse | null> = ref(null);
    const cureentScaleDetail: Ref<ScaleDetailItem | null> = ref(null);
    let detialLoading = ref(false);

    const active = ref("");

    let apexCharts: ApexCharts | null = null;

    const showRadarDetail = ref(false);

    const radarDetailStyle = ref({});

    const rootFontSize = parseFloat(
      window.getComputedStyle(document.documentElement).fontSize
    );
    let chartSize = rootFontSize * 2.7;

    let chartOptions = {
      chart: {
        type: "radar",
        height: chartSize * 2.5,
        toolbar: {
          show: false,
        },
        events: {
          xAxisLabelClick: function (event: any, chartContext: any, opts: any) {
            if (event.clientY > window.innerHeight / 2) {
              radarDetailStyle.value = {
                bottom: window.innerHeight - event.clientY + 30 + "px",
              };
            } else {
              radarDetailStyle.value = {
                top: event.clientY + 30 + "px",
              };
            }

            // var cache: any[] = [];
            // var json_str = JSON.stringify(event, function (key, value) {
            //   if (typeof value === "object" && value !== null) {
            //     if (cache.indexOf(value) !== -1) {
            //       return;
            //     }
            //     cache.push(value);
            //   }
            //   return value;
            // });
            // // cache = null; //释放cache

            // console.log(`点击位置：X=${x}, Y=${y},${json_str}`);

            var label = event.target.textContent;

            // 获取 x 轴的所有标签
            var labels = opts.config.xaxis.categories;

            // 找到点击的标签在数组中的索引
            var labelIndex = labels.indexOf(label);
            if (detailInfo.value) {
              cureentScaleDetail.value =
                detailInfo?.value.reportList[labelIndex];
              showRadarDetail.value = !showRadarDetail.value;
            }
          },
        },
      },
      title: { show: false, text: "" },
      stroke: { width: 1 },
      fill: { opacity: 0.5 },
      markers: { size: 0 },
      yaxis: {
        stepSize: 0.5,
        max: 3,
        labels: {
          offsetY: 20,
          style: { fontSize: "18px", fontWeight: 400, colors: "#666666" },
        },
      },
      series: [] as any[],
      xaxis: {
        categories: [] as any[],
        labels: {
          // offsetY: -2,
          style: {
            cssClass: "apexcharts-xaxis-label",
            fontSize: "22px",
            fontWeight: 600,
            colors: [
              "#508cf2",
              "#508cf2",
              "#508cf2",
              "#508cf2",
              "#508cf2",
              "#508cf2",
              "#508cf2",
              "#508cf2",
              "#508cf2",
            ],
          },
        },
      },
      legend: {
        show: true,
        fontSize: "18px",
        labels: {
          colors: "#242424",
        },
        height: 60,
        offsetY: 20,
        markers: {
          size: 10,
          fillColors: ["#3DCC54", "#508cf2"],
        },
        horizontalAlign: "right",
      },
      plotOptions: {
        radar: {
          size: chartSize,
          // offsetX: 0,
          offsetY: 30,
          polygons: {
            strokeColors: "#8e8e8e",
            strokeWidth: 1,
            connectorColors: "#8e8e8e",
            fill: {
              colors: undefined,
            },
          },
        },
      },
    };

    const close = () => {
      emit("closeEvaluationDetailView");
    };

    const scaleReportDetailRequest = async () => {
      detialLoading.value = true;
      linkardNet
        .getScaleReportDetail(userStore.linkardId, props.evalReportId)
        .then(
          (response) => {
            detailInfo.value = response;

            if (detailInfo.value.extraTitle && detailInfo.value.extraTitle != undefined && detailInfo.value.extraTitle.includes("\n")) {
              const regExp = new RegExp("\n", "g");
              detailInfo.value.extraTitle = detailInfo.value.extraTitle.replace(
                regExp,
                "<br>"
              );
            }

            detailInfo.value.createDate = getSlashDate(
              Number(detailInfo.value.createTime)
            );
            if (detailInfo.value && detailInfo.value.reportList.length > 0) {
              cureentScaleDetail.value = detailInfo.value.reportList[0];
            }
            detialLoading.value = false;

            if (detailInfo.value.type == "radar") {
              const userScale = {
                name: "您家孩子",
                color: "#508cf259",
                data: [] as any[],
              };

              const normalScale = {
                name: "正常范围",
                color: "#3DCC5459",
                data: [] as any[],
              };

              const xLabelCategories: any[] = [];

              let maxYaxis = 3;

              detailInfo.value.reportList.forEach((item) => {
                const total = Number(item.score.total);
                if (total > maxYaxis) {
                  maxYaxis = total;
                }
                userScale.data.push(Number(item.score.score));
                normalScale.data.push(Number(item.score.critical));
                xLabelCategories.push(item.group);
              });

              chartOptions.series = [normalScale, userScale];
              chartOptions.xaxis.categories = xLabelCategories;
              chartOptions.yaxis.max = maxYaxis;

              nextTick(() => {
                apexCharts = new ApexCharts(
                  document.querySelector("#chart"),
                  chartOptions
                );

                apexCharts?.render();
              });
            }
          },
          (ex) => {
            detialLoading.value = false;
            const error = ex as HttpResponse<any>;
            showToast(error.message);
          }
        );
    };

    const groupTabItemClick = (index: number) => {
      if (detailInfo.value) {
        cureentScaleDetail.value = detailInfo.value.reportList[index];
      }
    };

    // const apexChartsClick = (event: any, chartContext: any, config: any) => {
    //   console.log("点击click");
    // };

    // const dataPointSelection = (event: any, chartContext: any, config: any) => {
    //   console.log("点击dataPointSelection");
    // };

    // const apexChartsSelection = (chartContext: any, { xaxis, yaxis }: any) => {
    //   console.log("点击selection");
    // };

    const radarDetailCick = () => {
      showRadarDetail.value = !showRadarDetail.value;
    };

    onMounted(() => {
      scaleReportDetailRequest();
    });

    return {
      active,
      close,
      detialLoading,
      detailInfo,
      cureentScaleDetail,
      groupTabItemClick,
      radarDetailCick,
      chartOptions,
      showRadarDetail,
      radarDetailStyle,
      // apexChartsClick,
      // dataPointSelection,
      // apexChartsSelection,
    };
  },
});
</script>

<style lang="less" scoped>
.evaluation_detail_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 28px;
  background-color: rgb(240, 240, 240);
  width: calc(100% - 24px);
  height: calc(100% - 44px);
  border: 2px solid #242424;
  z-index: 2001;
  left: 10px;
  top: 16px;
  position: absolute;
  overflow: hidden;

  .head_content {
    font-size: 14px;
    color: #242424;
    line-height: 18px;
    font-weight: 600;
    width: calc(100% - 130px);
    margin-top: 2px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .evaluation_time {
      margin-top: 4px;
      height: 14px;
      line-height: 14px;
      font-size: 10px;
    }
  }

  .back_action {
    width: 48px;
    height: 48px;
    position: absolute;
    left: 10px;
    top: 20px;
  }

  .evaluation-detail-bg {
    margin-top: 10px;
    width: calc(100% - 30px);
    height: calc(100% - 100px);
    font-weight: 600;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-start;
    overflow-y: auto;

    .empty-card {
      width: 100%;
      font-size: 16px;
      color: #f0f0f0;
      margin: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-content: start;
      align-items: center;
      margin-top: calc(50% - 50px);

      .van-image {
        width: 100px;
      }

      .empty-card-text {
        margin-top: 12px;
        text-align: center;
        line-height: 24px;
        width: 100%;
        bottom: -20px;
        color: #242424;
        font-weight: 600;
      }
    }

    .evaluation-detail {
      margin: 0 0 20px 0;
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-self: flex-start;

      .top_info {
        font-size: 10px;
        line-height: 14px;
        text-align: left;
        margin: 16px 10px;
      }

      .bottom_info {
        width: 100%;
        height: calc(100% - 80px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-self: flex-start;

        .group_tab_bg {
          width: calc(100% - 16px);
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
          margin: 0 8px;
          height: auto;

          li {
            height: auto;
            width: auto;
            margin: 0 8px 6px 0;

            .group_tab_item {
              min-width: 48px;
              height: 26px;
              padding: 0 8px;
              border: 1px solid #3dcc54;
              color: #3dcc54;
              border-radius: 14px;

              font-size: 10px;
              font-weight: 600;
              line-height: 26px;
            }

            .group_tab_item_selected {
              background-color: #3dcc54;
              color: #f0f0f0;
            }

            .group_tab_item_extra {
              border: 2px solid #e70000;
              color: #e70000;
            }

            .group_tab_item_extra_selected {
              border: 2px solid #e70000;
              background-color: #e70000;
              color: #f0f0f0;
            }
          }
        }

        .space_line {
          width: calc(100% - 20px);
          height: 1px;
          background-color: rgba(36, 36, 36, 0.2);
          margin: 10px 10px 16px 10px;
        }

        .detail_text_bg {
          width: calc(100% - 32px);
          border-radius: 16px;
          border: 1px solid rgba(36, 36, 36, 0.2);
          flex-grow: 1;
          max-height: 260px;
          min-height: 160px;
          font-weight: 600;

          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 16px;
          overflow-y: scroll;
          text-align: left;

          .result_title {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 10px;
          }

          .result_title_extra {
            color: #e70000;
          }

          .result_detail {
            font-size: 10px;
            line-height: 14px;
          }

          .suggest_title {
            font-size: 14px;
            line-height: 18px;
            margin: 10px 0;
          }

          .suggest_detail {
            font-size: 10px;
            line-height: 14px;
          }
        }
      }
      .qr_code_info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 24px;
        .qr_code_img {
          width: 100px;
          height: 100px;
        }

        .qr_code_title {
          margin-bottom: 10px;
          font-size: 10px;
          line-height: 14px;
        }
      }
    }
  }

  .radar_detail_bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .radar_detail_click_view {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .detail_text_bg {
      position: absolute;
      background-color: #f0f0f0;
      border-radius: 10px;

      .detail_text_content {
        width: 240px;
        border-radius: 10px;
        flex-grow: 1;
        max-height: 180px;
        min-height: 120px;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 16px;
        overflow-y: scroll;
        text-align: left;
        margin: 10px 0;

        .result_title {
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 10px;
        }

        .result_detail {
          font-size: 10px;
          line-height: 14px;
        }

        .suggest_title {
          font-size: 12px;
          line-height: 18px;
          margin: 10px 0;
        }

        .suggest_detail {
          font-size: 10px;
          line-height: 14px;
        }
      }

      .detail_text_content::-webkit-scrollbar-track {
        background-color: transparent;
      }

      .detail_text_content::-webkit-scrollbar {
        width: 0px;
      }

      .detail_text_content::-webkit-scrollbar-thumb {
        width: 0px;
        border-radius: 0px;
      }
    }
  }
}
</style>

<style>
.apexcharts-xaxis-label {
  text-decoration: underline;
  border-bottom: 1px solid;
}
</style>
