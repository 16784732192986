<template>
  <div class="card-container" :style="{ backgroundImage: `url(${backgroundImg})` }">
    <div class="card-body">
      <div class="head_content">
        <span class="left_tips">LINKARD</span>
        <span>我的联卡</span>
        <div class="right_line"></div>
      </div>
      <div class="action_bg">
        <van-image class="back_action" :src="require('../assets/image/white_btn_icon.svg')"
          @click="toMyLinkard"></van-image>
        <div class="search-box">
          <van-search v-model="search" placeholder="请输入搜索关键词" @search="onSearch" @clear="clearSearch" />
        </div>
      </div>
      <div class="empty-card" v-show="filteredList.length == 0 && !listLoading">
        <van-image :src="require('../assets/image/icon-empty-dark.png')"></van-image>
        <div class="empty-card-text">
          <div v-if="list.length == 0">您暂无联卡</div>
          <div v-else>暂无内容</div>
        </div>
      </div>
      <div class="card-body-box" v-show="filteredList.length != 0">
        <van-pull-refresh class="list-pull-refresh-view" v-model="lisrefreshing" @refresh="listOnRefresh">
          <van-list loading-text="加载中..." v-model:loading="listLoading" :finished="listFinished" @load="listOnLoad"
            finished-text="">
            <van-grid class="models_van_grid" :column-num="2" :border="false">
              <van-grid-item class="card-item" v-for="linkInfoItem in filteredList" :key="linkInfoItem.id"
                @click="handleClick(linkInfoItem)">
                <div id="posterHtml">
                  <img :src="linkInfoItem.avatar" alt="" class="post-link-logo">
                  <div class="post-link-name">{{ linkInfoItem.name }}</div>
                  <div class="post-link-tags">
                    <template v-for="value, index in linkInfoItem.tags" :key="index">
                      <span v-if="value !== '' && value !== '无'">{{ value }} </span>
                    </template>
                  </div>
                  <div class="post-link-avatar">
                    <img :src="linkInfoItem.npcAvatar" alt="" />
                  </div>
                  <div class="post-link-path">linkard.me/{{ linkInfoItem.url }}</div>
                  <qrcode-vue class="post-erweima" :value="linkInfoItem.QRImgUrl" level="L" :margin="2" />
                </div>
              </van-grid-item>
            </van-grid>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <Loading v-show="listLoading" />
  </div>
</template>

<script lang="ts">
import { LinkardItem, linkardNet } from '@/net/linkardNet';
import { defineComponent, ref, onMounted, Ref, computed } from 'vue';
import QrcodeVue from 'qrcode.vue'
import type { Level, RenderAs } from 'qrcode.vue'
import { HttpResponse } from "@/net/http";
import { showToast } from "vant";

const redirectUri = process.env.VUE_APP_URL;

export default defineComponent({
  components: {
    QrcodeVue
  },
  setup(props, { emit }) {

    let search = ref('');
    let list: Ref<LinkardItem[]> = ref([]);
    let pageNum = 1;
    let pageSize = 10;
    let listRefresh = ref(false);
    let lisrefreshing = ref(false);
    let listLoading = ref(false);
    let listFinished = ref(false);

    const qrRenderAs = ref<RenderAs>('svg');

    const handleClick = async (item: LinkardItem) => {
      emit('close', item.url);
    };
    const listOnRefresh = () => {
      favoritesListRequest(true);
    };
    const listOnLoad = () => {
      favoritesListRequest(false);
    };
    const toMyLinkard = () => {
      emit('close');
    };
    const onSearch = (text: string) => {
      search.value = text;
    };
    const clearSearch = (text: string) => {
    };

    const filteredList = computed(() => { // 使用computed创建计算属性
      return !!search.value ? list.value.filter(i => i.name.toLowerCase().includes(search.value.toLowerCase())) : [...list.value];
    });

    const favoritesListRequest = async (pullDown: boolean) => {
      listLoading.value = true;
      if (pullDown) {
        pageNum = 1;
      }

      linkardNet.myHomeLinkardList(pageNum, pageSize, search.value).then(
        (response) => {
          lisrefreshing.value = false;
          listLoading.value = false;

          const tempInfoList = response.list;
          tempInfoList.forEach(item => {
            if (!item.tags) {
              item.tags = [];
            }
            item.QRImgUrl = redirectUri + '/' + item.url;
          });
          if (pullDown) {
            list.value = tempInfoList;
          } else {
            list.value = [...list.value, ...tempInfoList];
          }
          if (tempInfoList.length >= 10) {
            pageNum += 1;
            listFinished.value = false;
          } else {
            listFinished.value = true;
          }
        },
        (ex) => {
          lisrefreshing.value = false;
          listLoading.value = false;
          listFinished.value = true;
          const error = ex as HttpResponse<any>;
          showToast(error.message);
        },
      );
    };

    onMounted(() => {
      favoritesListRequest(true);
    });

    return {
      backgroundImg: require('../assets/image/default-body-bg.svg'),
      search,
      list,
      pageNum,
      pageSize,
      listRefresh,
      lisrefreshing,
      listLoading,
      listFinished,
      qrRenderAs,
      filteredList,
      toMyLinkard,
      onSearch,
      clearSearch,
      listOnRefresh,
      handleClick,
      listOnLoad,
    }
  }
});
</script>

<style lang="less" scoped>
.card-container {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 2001;

  .head_content {
    font-size: 12px;
    color: #242424;
    display: flex;
    font-weight: 700;
    align-items: center;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 56px;
    position: relative;

    .left_tips {
      position: absolute;
      left: 20px;
    }

    .right_line {
      position: absolute;
      right: 20px;
      height: 1px;
      width: 40px;
      background-color: #242424;
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    background: rgb(240, 240, 240);
    height: calc(100% - 32px);
    border: 2px solid #242424;
    border-radius: 28px;
    margin: 16px 10px;

    .empty-card {
      width: 100%;
      font-size: 16px;
      color: #f0f0f0;
      margin: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-content: start;
      align-items: center;
      bottom: 20px;

      .van-image {
        width: 100px;
      }

      .empty-card-text {
        margin-top: 12px;
        text-align: center;
        line-height: 24px;
        width: 100%;
        bottom: -20px;
        color: #242424;
        font-weight: 600;
      }
    }

    .action_bg {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 0px;
      position: relative;

      .back_action {
        width: 48px;
        height: 48px;
        margin-left: 10px;
      }

      .search-box {
        flex-grow: 1;
        margin-left: 20px;
        margin-right: 20px;
        height: 48px;
        line-height: 48px;
        background: #242424;
        border-radius: 48px;

        .van-search {
          padding: 0;
          height: 100%;
          overflow: hidden;
          border-radius: 48px;
          background: #242424;

          :deep(.van-cell) {
            // padding: 0;

            .van-field__left-icon {
              color: #fff;
              font-size: 16px;
            }
          }

          :deep(.van-search__content) {
            background: #242424;

            .van-field__control {
              background-color: auto;
              color: #fff;
            }

            input::input-placeholder {
              color: rgba(255, 255, 255, 0.5);
            }
          }
        }
      }
    }

    .card-body-box {
      display: flex;
      margin: 35px 0 20px 0;
      padding: 0 10px;
      flex-wrap: wrap;
      justify-content: space-between;
      height: calc(100% -275px);
      overflow-y: scroll;
      flex-grow: 1;

      .card-item {
        width: 160px;
        height: 214px;
        font-size: 12px;
        color: #242424;
        margin-bottom: 10px;
        flex-basis: auto !important;
        --van-grid-item-content-background: transparent;
        --van-grid-item-content-padding: 6px;

        .van-image {
          flex: 1;
        }

        .card-item-name {
          margin-top: 10px;
        }

        #posterHtml {
          position: relative;
          background: url('../assets/image/share-my.jpg') no-repeat;
          width: 100%;
          height: 100%;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          border-radius: 18px;
        }

        .post-erweima {
          position: absolute;
          width: 50px;
          height: 50px;
          border-radius: 8px;
          left: 8px;
          bottom: 26px;
        }

        .post-link-logo {
          width: 35px;
          height: 35px;
          border-radius: 35px;
          margin-top: 38px;
          margin-left: 15px;
        }

        .post-link-name {
          font-size: 12px;
          color: #fff;
          margin-left: 12px;
          font-weight: 600;
          margin-top: 14px;
        }

        .post-link-tags {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 12px;
          // flex: 1;
          margin-left: 20px;
          // margin-top: 30px;

          span {
            margin-right: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            color: #f0f0f0;
            border-radius: 16px;
            border: 1px solid #F0F0F0;
            font-size: 6px;
            padding: 2px 4px;
            // margin-left: 10px;
          }
        }

        .post-link-avatar {
          // display:none;
          width: 80px;
          height: 34px;
          border-radius: 8px 0px 0px 8px;
          background: #f0f0f0;
          position: absolute;
          right: 0;
          bottom: 40px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          img {
            height: 100%;
          }
        }

        .post-link-path {
          transform: scale(0.8);
          width: 100px;
          position: absolute;
          color: #f0f0f0;
          font-size: 10px;
          bottom: 26px;
          left: 70px;
          text-align: left;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;

        }
      }


      .list-pull-refresh-view {
        width: 100%;

        .models_van_grid {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>

<style>
.van-grid-item__content {
  padding: 0;
  background: transparent;
}
</style>