import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-89380d24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "umi-container" }
const _hoisted_2 = { class: "chat-content" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.text), 1),
      (_ctx.fromAudio)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "from_audio_img",
            src: require('../assets/image/chat_message_from_audio.png')
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true)
    ])
  ]))
}