<template>
  <div class="cabinet_bg" :style="{ backgroundImage: `url(${backgroundImg})` }">
    <div class="top_action_and_title">
      <van-image v-if="!cabinetId || cabinetId == 'undefined'" class="back_action"
        :src="require('../assets/image/white_btn_icon.svg')" @click="toMyLinkard"></van-image>
      <div :class="['cabinet_name', cabinetId && cabinetId != 'undefined' ? 'cabinet_name_center' : '']">{{
    cabinetInfo?.cabinetName }}</div>
    </div>
    <div class="special_subject_list_bg">
      <div class="special_subject_item_bg" v-for="(specialTopItem, index) in cabinetInfo?.specialTopicList"
        :key="index">
        <img class="special_subject_img" :src="specialTopItem.url" alt="" />
        <span class="special_subject_name">{{ specialTopItem.name }}</span>
        <div class="post-wrapper" v-for="(linkardInfo, index) in specialTopItem.linkardList" :key="'item_' + index">
          <div id="posterHtml" @click="linkardItemClick(linkardInfo)">
            <img :src="linkardInfo.avatar" alt="" class="post-link-logo">
            <div class="post-link-name">{{ linkardInfo.name }}</div>
          </div>
          <div class="custom_linkard_name">{{ linkardInfo.showName }}</div>
        </div>
      </div>
    </div>
    <Loading v-show="listLoading" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, Ref } from 'vue';
import Loading from './loading.vue';
import { CabinetInfoResponse, LinkardItem, linkardNet } from '@/net/linkardNet';
import { showToast } from 'vant';

export default defineComponent({
  components: {
    Loading
  },
  props: {
    linkardId: {
      type: String,
      default: ""
    },
    cabinetUserId: {
      type: String,
      default: ""
    },
    cabinetId: {
      type: String,
      default: ""
    }
  },
  setup(props, { emit }) {
    let listLoading = ref(false);
    let cabinetInfo: Ref<CabinetInfoResponse | undefined> = ref();

    const cabinetListRequest = async () => {
      listLoading.value = true;
      if (props.cabinetUserId) {
        linkardNet.getCabinetInfoByUser(props.cabinetUserId).then(
          (response) => {
            listLoading.value = false;
            cabinetInfo.value = response;
          },
          (ex) => {
            showToast("获取卡柜信息失败");
            listLoading.value = false;
          },
        );
      } else if (props.cabinetId && props.cabinetId != 'undefined') {
        linkardNet.cabinetInfoById(props.cabinetId).then(
          (response) => {
            listLoading.value = false;
            cabinetInfo.value = response;
          },
          (ex) => {
            showToast("获取卡柜信息失败");
            listLoading.value = false;
          },
        );
      } else {
        linkardNet.getCabinetInfo(props.linkardId).then(
          (response) => {
            listLoading.value = false;
            cabinetInfo.value = response;
          },
          (ex) => {
            showToast("获取卡柜信息失败");
            listLoading.value = false;
          },
        );
      }
    };

    const linkardItemClick = (item: LinkardItem) => {
      emit('close', item.url);
    };

    const toMyLinkard = () => {
      emit('close');
    };

    onMounted(() => {
      cabinetListRequest();
    });

    return {
      listLoading,
      cabinetInfo,
      toMyLinkard,
      linkardItemClick,
      backgroundImg: require('../assets/image/cabinet_share_bg.svg'),
    }
  }
});

</script>
<style lang="less" scoped>
.cabinet_bg {
  height: 100%;
  background: #242424;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;

  .top_action_and_title {
    margin-top: 50px;
    height: 66px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    .back_action {
      width: 48px;
      height: 48px;
      position: absolute;
      top: 6px;
      left: 26px;
    }

    .cabinet_name {
      width: 300px;
      height: 24px;
      line-height: 24px;
      color: #F0F0F0;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      margin-left: 60px;
    }

    .cabinet_name_center {
      margin-left: 0px;
    }
  }

  .special_subject_list_bg {
    height: calc(100% - 120px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    margin-top: 6px;

    .special_subject_item_bg {
      height: auto;
      border-radius: 28px;
      border: 2px solid #242424;
      flex-wrap: wrap;
      display: flex;
      flex-direction: row;
      padding-left: 16px;
      margin: 0 10px 20px 10px;
      position: relative;

      .special_subject_img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        position: absolute;
        top: 0;
        left: 0;
      }

      .special_subject_name {
        width: 279px;
        height: 40px;
        line-height: 40px;
        vertical-align: middle;
        border-radius: 36px;
        background: #F0F0F0;

        color: #242424;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        margin: 24px 0 24px 22px;

        z-index: 1;
      }

      .post-wrapper {
        width: 100px;
        height: auto;
        /* 10px为间隙，可根据需要调整 */
        margin: 0 3px 20px 3px;
        /* 间隙，可根据需要调整 */
        box-sizing: border-box;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        #posterHtml {
          position: relative;
          background: url('../assets/image/share-my.jpg') no-repeat;
          width: 100%;
          height: 132px;
          background-size: cover;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

        }

        .post-link-logo {
          width: 48px;
          height: 48px;
          border-radius: 24px;
          margin-left: 16px;
          object-fit: cover;
        }

        .post-link-name {
          font-size: 8px;
          color: #fff;
          margin-left: 16px;
          font-weight: 600;
          margin-top: 8px;
          text-align: center;
        }

        .custom_linkard_name {
          width: 100%;
          height: 20px;
          margin-top: 4px;
          border-radius: 10px;
          background: #F0F0F0;
          box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

          color: #242424;
          text-align: center;
          font-size: 10px;
          font-weight: 600;
          line-height: 20px;
        }
      }
    }
  }
}
</style>