import { createApp } from "vue";
import App from "./App.vue";
import { store } from "./stores";
import router from "./router";
import VConsole from "vconsole";
import Vant from "vant";
// 全局样式
import "../src/assets/fonts/font.css";
import "./styles/index.less";

import "../rem";
import "video.js/dist/video-js.css";
import "vant/lib/index.css";
import 'amfe-flexible';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(Vant);
app.mount("#app");

let vConsole = null;
if (process.env.VUE_APP_ENV !== "production") {
  vConsole = new VConsole();
  app.config.warnHandler = () => {};
}