import { CurrentUserInfo } from "@/net/linkardNet";
import { defineStore } from "pinia";
/**
 * 用户数据仓库
 */
export const useUserStore = defineStore("userInfo", {
  state: () => {
    return {
      userInfo: {} as CurrentUserInfo,
      linkardId: "",
    };
  },

  // 开启数据缓存
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage, // 表示存储在localStorage
        paths: ["userInfo"], // 指定要长久化的字段
      },
    ],
  },

  actions: {
    init() {
      const info = "{}";
      this.userInfo = JSON.parse(info);
    },

    /**
     * 获取当前用户信息
     */
    updateCurrentUserInfo(response: CurrentUserInfo) {
      this.userInfo = response;
    },

    /**
     * 获取当前用户信息
     */
    getCurrentUserInfo(): CurrentUserInfo | undefined {
      return this.userInfo;
    },
  },
});
