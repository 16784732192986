<template>
  <router-view>
    <!-- <keep-alive :include="cachedViews">
      <component :is="Component" />
    </keep-alive> -->
  </router-view>
</template>

<script lang="ts">
// import { useCachedViewStoreHook } from '@/stores/cachedView';
import { computed } from 'vue';

import { useUserStore } from '@/stores/user';

export default {
  setup() {
    const userStore = useUserStore();
    userStore.init();
    // const cachedViews = computed(() => useCachedViewStoreHook().cachedViewList);
    return {
      // cachedViews
    };
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
