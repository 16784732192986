<template>
    <div class="umi-container">
        <div class="chat-content">
            <span>{{ text }}</span>
            <img v-if="fromAudio" class="from_audio_img"
                :src="require('../assets/image/chat_message_from_audio.png')" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
    name: 'OwnChat',
    props: {
        text: {
            type: String,
            default: '',
        },
        fromAudio: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        onMounted(() => {
        });
        return {
        };
    },
});

</script>

<style lang="less" scoped>
.umi-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 26px;

    .chat-content {
        font-size: 12px;
        margin-left: 20px;
        padding: 14px 20px;
        color: #242424;
        font-weight: 500;
        background: #F3EC3F;
        border-radius: 20px;
        text-align: left;
        line-height: 18px;
        word-break: break-all;
        max-width: 220px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .from_audio_img {
            width: 20px;
            height: 16px;
            margin: 0px -6px 0 10px;
        }
    }
}

.slide-item {
    animation-name: slidedown;
    animation-fill-mode: forwards;
    animation-duration: 1000ms;
    opacity: 0;
}

@keyframes slidedown {
    0% {
        transform: translateY(-80%);
        opacity: 0;
    }

    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}
</style>